import * as React from "react";
import { connect } from "react-redux";
//import { IAppState } from '../../redux/store/Store';
import { ThunkDispatch } from "redux-thunk";

class indexParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  public render() {
    return (
      <React.Fragment>
        <div className="layout-content">
          <iframe
            src="https://dev-services.yakchat.com:8188/YakLogin.html"
            height="100%"
            width="100%"
            frameBorder="0"
            title="Iframe Example"
          ></iframe>
        </div>
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {};
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(indexParentComponent);
