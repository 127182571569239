import * as interfaceObject from "../reducers/modelInterface";

export enum CharacterActionTypes {
  GET_ALL = "GET_ALL",
  GET_ALL_USERSLIST = "GET_ALL_USERSLIST",
  SUCCESS_SAVED = "SUCCESS_SAVED",
  LOADER_HEADER = "LOADER_HEADER",
  ERROR_SAVED = "ERROR_SAVED",
  HIDE_GROUP_MEMBERS_GROUP_TAB = "HIDE_GROUP_MEMBERS_GROUP_TAB",
  SHOW_HIDE_LIST = "SHOW_HIDE_LIST",
  COMPANY_LIST = "COMPANY_LIST",
  DELETED_SUCCESSFULLY_MESSAGE = "DELETED_SUCCESSFULLY_MESSAGE",
  SUBSCRIPTION_LIST = "SUBSCRIPTION_LIST",
  PENDING_SUBSCRIPTION_LIST = "PENDING_SUBSCRIPTION_LIST",
  PENDING_USER_LIST = "PENDING_USER_LIST",
  PENDING_COMPANY_LIST = "PENDING_COMPANY_LIST",
  SELECTED_COMPANY_NAME = "SELECTED_COMPANY_NAME",
  GET_PHONE_NUMBER_LIST = "GET_PHONE_NUMBER_LIST",
  PENDING_NUMBER_LIST = "PENDING_NUMBER_LIST",
  SHOW_HIDE_LIST_COMPANY = "SHOW_HIDE_LIST_COMPANY",
  SHOW_HIDE_LIST_USER = "SHOW_HIDE_LIST_USER",
  SUBSCRIPTION_LIST_LENGTH = "SUBSCRIPTION_LIST_LENGTH",
  USER_FILTER_LIST = "USER_FILTER_LIST",
  SUBSCRIPTION_FILTER_LIST = "SUBSCRIPTION_FILTER_LIST",
  FILTER_NUMBER_LIST = "FILTER_NUMBER_LIST",
  SHOW_HIDE_LIST_NUMBERS = "SHOW_HIDE_LIST_NUMBERS",
  GROUP_LIST_BY_SUBID_COMPID = "GROUP_LIST_BY_SUBID_COMPID",
  PENDING_GROUP_LIST_BY_SUBID_COMPID = "PENDING_GROUP_LIST_BY_SUBID_COMPID",
  GROUP_MEMBER_LIST_BY_EMAIL = "GROUP_MEMBER_LIST_BY_EMAIL",
  PENDING_MEMBER_LIST_BY_EMAIL = "PENDING_MEMBER_LIST_BY_EMAIL",
  GROUP_NOT_MEMBER_LIST_BY_EMAIL = "GROUP_NOT_MEMBER_LIST_BY_EMAIL",
  PENDING_NOT_MEMBER_LIST_BY_EMAIL = "PENDING_NOT_MEMBER_LIST_BY_EMAIL",
  ICON_NAME_GROUP_NON_GROUP_MEMBERS = "ICON_NAME_GROUP_NON_GROUP_MEMBERS",
  HIDE_SHOW_SELECT_ALL = "HIDE_SHOW_SELECT_ALL",
  HIDE_SHOW_SELECT_ALL_NOT_GROUP_MEMBER = "HIDE_SHOW_SELECT_ALL_NOT_GROUP_MEMBER",
  FILTERED_GROUP_MEMBER_LIST = "FILTERED_GROUP_MEMBER_LIST",
  FILTERED_NON_GROUP_MEMBER_LIST = "FILTERED_NON_GROUP_MEMBER_LIST",
  GROUP_LIST_FILTER = "GROUP_LIST_FILTER",
  DISPLAY_ERROR_LIST_MESSAGE = "DISPLAY_ERROR_LIST_MESSAGE",
  GET_ACCESS_LEVEL_LOGGED_IN_USER = "GET_ACCESS_LEVEL_LOGGED_IN_USER",
  SHOW_HIDE_LIST_GROUP = "SHOW_HIDE_LIST_GROUP",
  GORUP_SIDE_NAV_CLICK = "GORUP_SIDE_NAV_CLICK",
  HIDE_COMPANY_TAB = "HIDE_COMPANY_TAB",
  SELECTED_GROUP_NAME = "SELECTED_GROUP_NAME",
  ACTIVE_TAB_NAME = "ACTIVE_TAB_NAME",
  HIDE_GROUP_NON_MEMBER_TAB = "HIDE_GROUP_NON_MEMBER_TAB",
  GROUP_EDIT_OBJECT = "GROUP_EDIT_OBJECT",
  HIDE_GROUP_EMAIL_HEADING = "HIDE_GROUP_EMAIL_HEADING",
  FILTER_NUMBERS = "FILTER_NUMBERS",
  SERVICE_PLAN_LIST = "SERVICE_PLAN_LIST",
  LOGGED_IN_USER_DETAIL = "LOGGED_IN_USER_DETAIL",
  SELECTED_GROUP_EMAIL = "SELECTED_GROUP_EMAIL",
  PENDING_LOGGED_IN_RESULT = "PENDING_LOGGED_IN_RESULT",
  PENDING_RESULT_FROM_TWILLIO = "PENDING_RESULT_FROM_TWILLIO",
  LOCAL_NUMBER_LIST_TWILLIO = "LOCAL_NUMBER_LIST_TWILLIO",
  MOBILE_NUMBER_LIST_TWILLIO = "MOBILE_NUMBER_LIST_TWILLIO",
  SAVE_TWILLIO_NUMBER_TO_DATABASE = "SAVE_TWILLIO_NUMBER_TO_DATABASE",
  PENDING_ARENT_COMPANY_LIST = "PENDING_ARENT_COMPANY_LIST",
  PARENT_COMPANY_LIST = "PARENT_COMPANY_LIST",
  TOGGLE_CLASS_SIDE_NAV = "TOGGLE_CLASS_SIDE_NAV",
  LOCAL_RESPONSE_SHOW = "LOCAL_RESPONSE_SHOW",
  SHOW_UNIQUE_NUMBER = "SHOW_UNIQUE_NUMBER",
  EMAIL_FOR_ADD_EDIT_USER = "EMAIL_FOR_ADD_EDIT_USER",
  NAME_FOR_ADD_EDIT_USER = "NAME_FOR_ADD_EDIT_USER",
  SUBSCRIPTION_ID_FOR_ADD_EDIT_USER = "SUBSCRIPTION_ID_FOR_ADD_EDIT_USER",
  SUBSCRIPTION_NAME_FOR_ADD_EDIT_USER = "SUBSCRIPTION_NAME_FOR_ADD_EDIT_USER",
  UNIQUE_NUMBER_COST_FOR_ADD_EDIT_USER = "UNIQUE_NUMBER_COST_FOR_ADD_EDIT_USER",
  CREDIT_LIMIT_FOR_ADD_EDIT_USER = "CREDIT_LIMIT_FOR_ADD_EDIT_USER",
  ROLE_ID_FOR_ADD_EDIT_USER = "ROLE_ID_FOR_ADD_EDIT_USER",
  STORE_TEXT_FOR_ADD_EDIT_USER = "STORE_TEXT_FOR_ADD_EDIT_USER",
  DISABLED_FOR_ADD_EDIT_USER = "DISABLED_FOR_ADD_EDIT_USER",
//Task #3461 
//Maksud
  CONTACT_LOOKUP_ENABLED_EDIT_USER = "CONTACT_LOOKUP_ENABLED_EDIT_USER",  
  SUBSCRIPTION_TENANT_ID_MICROSOFT_CONTACT_LOOKUP = "SUBSCRIPTION_TENANT_ID_MICROSOFT_CONTACT_LOOKUP",

  USER_DETAIL_OBJ = "USER_DETAIL_OBJ",
  DEFAULT_COUNTRY_CODE = "DEFAULT_COUNTRY_CODE",
  REGISTERATION_DATE_FOR_ADD_EDIT = "REGISTERATION_DATE_FOR_ADD_EDIT",
  USED_CREDIT_FOR_ADD_EDIT_USER = "USED_CREDIT_FOR_ADD_EDIT_USER",
  SAVED_SELECTED_COMPANY_DETAIL = "SAVED_SELECTED_COMPANY_DETAIL",
  HIDE_USER_LIST_FROM_COMPANY_TAB = "HIDE_USER_LIST_FROM_COMPANY_TAB",
  CURRENT_ACTIVE_TAB_NAME = "CURRENT_ACTIVE_TAB_NAME",
  HIDE_GROUP_TAB_FROM_COMPANY_DETAIL = "HIDE_GROUP_TAB_FROM_COMPANY_DETAIL",
  USER_EDIT_BREADCRUMB_NUMBER = "USER_EDIT_BREADCRUMB_NUMBER",
  PENDING_LOGGED_IN_COMPANY_DETAIL = "PENDING_LOGGED_IN_COMPANY_DETAIL",
  MY_COMPANY_NAME = "MY_COMPANY_NAME",
  MY_COMPANY_EMAIL = "MY_COMPANY_EMAIL",
  MY_COMPANY_PRIMARY_CONTACT_NAME = "MY_COMPANY_PRIMARY_CONTACT_NAME",
  MY_COMPANY_PRIMARY_CONTACT_NUMBER = "MY_COMPANY_PRIMARY_CONTACT_NUMBER",
  MY_COMPANY_ID = "MY_COMPANY_ID",
  MY_COMPANY_LINE3 = "MY_COMPANY_LINE3",
  MY_COMPANY_COUNTRY = "MY_COMPANY_COUNTRY",
  MY_COMPANY_ZIPCODE = "MY_COMPANY_ZIPCODE",
  MY_COMPANY_CURRENCY = "MY_COMPANY_CURRENCY",
  MY_COMPANY_CITY = "MY_COMPANY_CITY",
  UNASSIGN_PHONE_NUMBERS = "UNASSIGN_PHONE_NUMBERS",
  PENDING_UNASSIGN_PHONE_NUMBERS = "PENDING_UNASSIGN_PHONE_NUMBERS",
  SUBSCRIPTON_ADD_CALLED_FROM_PHONE_NUMBER = "SUBSCRIPTON_ADD_CALLED_FROM_PHONE_NUMBER",
  USER_ADD_CALLED_FROM_PHONE_NUMBER = "USER_ADD_CALLED_FROM_PHONE_NUMBER",
  GROUP_ADD_CALLED_FROM_PHONE_NUMBER = "GROUP_ADD_CALLED_FROM_PHONE_NUMBER",
  GROUP_FOR_ADD_EDIT_USER = "GROUP_FOR_ADD_EDIT_USER",
  ENABLE_EMAIL_FOR_ADD_EDIT_USER = "ENABLE_EMAIL_FOR_ADD_EDIT_USER",
  COST_CODE_FOR_ADD_EDIT_USER = "COST_CODE_FOR_ADD_EDIT_USER",
  MY_COMPANY_IS_PARENT = "MY_COMPANY_IS_PARENT",
  MY_COMPANY_PARENT_ID = "MY_COMPANY_PARENT_ID",
  MY_COMPANY_SUBSCRIPTION_COST = "MY_COMPANY_SUBSCRIPTION_COST",
  MY_COMPANY_REGISTERATION_DATE = "MY_COMPANY_REGISTERATION_DATE",
  MY_COMPANY_PARTNER_PARENT_ID = "MY_COMPANY_PARTNER_PARENT_ID",
  SEARCH_TEXT_BOX_VALUE = "SEARCH_TEXT_BOX_VALUE",
  FILTER_COMPANYLIST = "FILTER_COMPANYLIST",
  SEARCH_TEXT_BOX_VALUE_SUBSCRIPTION = "SEARCH_TEXT_BOX_VALUE_SUBSCRIPTION",
  SEARCH_TEXT_BOX_VALUE_USER = "SEARCH_TEXT_BOX_VALUE_USER",
  SEARCH_TEXT_BOX_VALUE_GROUP = "SEARCH_TEXT_BOX_VALUE_GROUP",
  WARNING_PHONE_NUMBER = "WARNING_PHONE_NUMBER",
  PHONE_NUMBER_PURCHASED = "PHONE_NUMBER_PURCHASED",
  CHANGE_BUTTON_TEXT = "CHANGE_BUTTON_TEXT",
  WHITE_LABEL_FOR_USER = "WHITE_LABEL_FOR_USER",
  WARNING_AFTER_GROUP_ADD = "WARNING_AFTER_GROUP_ADD",
  ROW_ACTIVE_ID_COMPANY = "ROW_ACTIVE_ID_COMPANY",
  ROW_ACTIVE_ID = "ROW_ACTIVE_ID",
  ROW_ACTIVE_ID_SUBSCRIPTION = "ROW_ACTIVE_ID_SUBSCRIPTION",
  ROW_ACTIVE_ID_GROUPS = "ROW_ACTIVE_ID_GROUPS",
  ROW_ACTIVE_ID_NUMBERS = "ROW_ACTIVE_ID_NUMBERS",
  LOGGED_IN_USER_DISABELD = "LOGGED_IN_USER_DISABELD",
  EMAIL_ENABLED_FOR_USER = "EMAIL_ENABLED_FOR_USER",
  WHITE_BRAND_LOGGED_IN_USER = "WHITE_BRAND_LOGGED_IN_USER",

  /*API calls by Niyi*/
  GET_ALL_SERVICE_PROVIDERS = "GET_ALL_SERVICE_PROVIDERS",
  GET_ALL_SERVICE_PROVIDERS_ACCOUNT = "GET_ALL_SERVICE_PROVIDERS_ACCOUNT",
  ADD_UPDATE_PHONE_NUMBERS = "ADD_UPDATE_PHONE_NUMBERS",
  MODIFY_FREE_CREDIT_DATE = "MODIFY_FREE_CREDIT_DATE",
  /*API calls by Niyi*/
}

export interface ICharacterGetAllAction {
  type: CharacterActionTypes.GET_ALL;
  characters: interfaceObject.ICharacter[];
}
export interface IUserListGetAllUser {
  type: CharacterActionTypes.GET_ALL_USERSLIST;
  userList: interfaceObject.IUserList[];
}
