import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import NumbersComponent from "./components/numbersComponent/numbersParentComponent";
import CompanyParentComponent from "./components/companyComponent/companyParentComponent";
import SubscriptionComponent from "./components/subscriptionComponent/subscriptionParentComponent";
import UserParentComponent from "./components/userComponent/usersParentComponent";
import GroupsParentComponent from "./components/groupsComponent/groupParentComponent";
import MyProfileComponent from "./components/myProfileComponent/myProfileComponent";
import MyCompanyComponent from "./components/myCompanyComponent/myCompanyComponent";
import ErrorPageComponent from "./components/ErrorPageComponent/ErrorPageComponent";
import InboxComponent from "./components/inboxComponent/indexParentComponent";
import IdleComponent from "./components/idleComponent/idleComponent";
export default class Routes extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      roleNameState: "",
    };
  }
  componentDidMount() {}

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.permissions !== prevState.roleNameState) {
      return { roleNameState: nextProps.permissions };
    } else return null;
  }
  userRoleRoutes = () => {
    return (
      <Switch>
        {/* <Route path="/" exact>
          <MyProfileComponent />
        </Route> */}
        <Route path="/MyProfile">
          <MyProfileComponent />
        </Route>
        <Route
          path="/ErrorPage"
          component={(props: any) => <ErrorPageComponent {...props} />}
        />
        <Route path="/MyCompany">
          <MyCompanyComponent />
        </Route>
        <Route path="/IdlePage">
          <IdleComponent />
        </Route>
      </Switch>
    );
  };

  companyRoleRoutes = () => {
    return (
      <Switch>
        {/* <Route
          path="/"
          exact
          component={(props: any) => <UserParentComponent {...props} />}
        /> */}
        <Route
          path="/Users"
          component={(props: any) => <UserParentComponent {...props} />}
        /> 
        <Route path="/MyProfile">
          <MyProfileComponent />
        </Route>
        <Route path="/Groups">
          <GroupsParentComponent />
        </Route>
        <Route path="/Subscription">
          <SubscriptionComponent />
        </Route>
        <Route
          path="/ErrorPage"
          component={(props: any) => <ErrorPageComponent {...props} />}
        />
        <Route path="/MyCompany">
          <MyCompanyComponent />
        </Route>
        <Route path="/IdlePage">
          <IdleComponent />
        </Route>
      </Switch>
    );
  };

  superAdminRoleRoutes = () => {

    return (
      <Switch>
        {
          // Maksud
        /* <Route
          path="/"
          exact
          component={(props: any) => <UserParentComponent {...props} />}
        /> */}        
        <Route
          path="/Users"
          component={(props: any) => <UserParentComponent {...props} />}
        />
        <Route path="/MyProfile">
          <MyProfileComponent />
        </Route>
        <Route
          path="/Groups"
          component={(props: any) => <GroupsParentComponent {...props} />}
        />
        <Route
          path="/Numbers"
          component={(props: any) => <NumbersComponent {...props} />}
        />
        <Route
          path="/Company"
          render={(props: any) => <CompanyParentComponent {...props} />}
          exact
        ></Route>
        <Route
          path="/ErrorPage"
          component={(props: any) => <ErrorPageComponent {...props} />}
        />
        <Route path="/Subscription">
          {" "}
          <SubscriptionComponent />
        </Route>
        <Route path="/MyCompany">
          <MyCompanyComponent />
        </Route>
        <Route path="/Inbox">
          <InboxComponent />
        </Route>
        <Route path="/IdlePage">
          <IdleComponent />
        </Route>
      </Switch>
    );
  };
  partnerRoleRoutes = () => {
    return (
      <Switch>
        {/* <Route
          path="/"
          exact
          component={(props: any) => <UserParentComponent {...props} />}
        /> */}
        <Route
          path="/Company"
          render={() => {
            return <CompanyParentComponent />;
          }}
        ></Route>
        <Route path="/Subscription">
          {" "}
          <SubscriptionComponent />
        </Route>
        <Route path="/Users">
          <UserParentComponent />{" "}
        </Route>
        <Route path="/Groups">
          <GroupsParentComponent />{" "}
        </Route>
        <Route
          path="/Numbers"
          component={(props: any) => <NumbersComponent {...props} />}
        />
        <Route path="/MyProfile">
          <MyProfileComponent />
        </Route>
        <Route
          path="/ErrorPage"
          component={(props: any) => <ErrorPageComponent {...props} />}
        />
        <Route path="/MyCompany">
          <MyCompanyComponent />
        </Route>
        <Route path="/IdlePage">
          <IdleComponent />
        </Route>
      </Switch>
    );
  };
  render() {
    return this.state.roleNameState === 20 ? (
      this.userRoleRoutes()
    ) : this.state.roleNameState === 0 ? (
      this.companyRoleRoutes()
    ) : this.state.roleNameState === -10 ? (
      this.superAdminRoleRoutes()
    ) : this.state.roleNameState === 5 ? (
      this.partnerRoleRoutes()
    ) : (
      <Switch>
        <Route
          path="/ErrorPage"
          component={(props: any) => <ErrorPageComponent {...props} />}
        />
      </Switch>
    );
  }
}
