import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import * as action from "../../redux/actions/actions";
class SideBarComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addActiveClass: true,
    };
  }

  componentDidMount() {}
  userRole() {
    return (
      <ul className="sidenav-inner ps">
        <div
          className="ps__rail-x"
          style={{
            left: "0px",
            bottom: "0px",
          }}
        >
          <div
            className="ps__thumb - x"
            style={{ left: "0px", width: "0px" }}
          ></div>
        </div>
        <div className="ps__rail-y" style={{ top: "0px", right: "4px" }}>
          <div
            className="ps__thumb-y"
            style={{
              top: "0px",
              height: "0px",
            }}
          ></div>
        </div>
      </ul>
    );
  }
  companyRole() {
    return (
      <ul className="sidenav-inner ps">
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            onClick={() => {
              this.props.showHideListSub(true);
            }}
            to="/Subscription"
          >
            <span className="font-icon">&#68;</span>Subscriptions
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            onClick={() => {
              this.props.showHideListUser(true);
            }}
            to="/Users"
          >
            <span className="font-icon">&#71;</span>Users
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            to="/Groups"
            onClick={() => {
              this.props.showHideGroup(false);
            }}
          >
            <span className="font-icon">&#112;</span>Groups
          </NavLink>
        </li>
        <li className="sidenav-divider mb-1"></li>
        <div
          className="ps__rail-x"
          style={{
            left: "0px",
            bottom: "0px",
          }}
        >
          <div
            className="ps__thumb - x"
            style={{ left: "0px", width: "0px" }}
          ></div>
        </div>
        <div className="ps__rail-y" style={{ top: "0px", right: "4px" }}>
          <div
            className="ps__thumb-y"
            style={{
              top: "0px",
              height: "0px",
            }}
          ></div>
        </div>
      </ul>
    );
  }
  superAdminRole() {
    return (
      <ul className="sidenav-inner ps">
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            to="/Inbox"
            onClick={() => {
              this.props.selectedCompanyNameProps("");
              this.props.showHideListProp(true);
            }}
          >
            <span className="font-icon">y</span>Inbox
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            to="/Company"
            onClick={() => {
              this.props.selectedCompanyNameProps("");
              this.props.showHideListProp(true);
            }}
          >
            <span className="font-icon">&#104;</span>Companies
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            className="sidenav-link"
            activeClassName="active"
            onClick={() => {
              this.props.showHideListSub(true);
            }}
            to="/Subscription"
          >
            <span className="font-icon">&#68;</span>Subscriptions
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            className="sidenav-link"
            activeClassName="active"
            onClick={() => {
              this.props.showHideListUser(true);
            }}
            to="/Users"
          >
            <span className="font-icon">&#71;</span>Users
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            className="sidenav-link"
            activeClassName="active"
            to="/Groups"
            onClick={() => {
              this.props.showHideGroup(false);
            }}
          >
            <span className="font-icon">&#112;</span>Groups
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            onClick={() => {
              this.props.showHideListNumbers(true);
            }}
            to="/Numbers"
          >
            <span className="font-icon">&#119;</span>Numbers
          </NavLink>
        </li>
        <li className="sidenav-divider mb-1"></li>
        <div
          className="ps__rail-x"
          style={{
            left: "0px",
            bottom: "0px",
          }}
        >
          <div
            className="ps__thumb - x"
            style={{ left: "0px", width: "0px" }}
          ></div>
        </div>
        <div className="ps__rail-y" style={{ top: "0px", right: "4px" }}>
          <div
            className="ps__thumb-y"
            style={{
              top: "0px",
              height: "0px",
            }}
          ></div>
        </div>
      </ul>
    );
  }
  partnerRole() {
    return (
      <ul className="sidenav-inner ps">
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            to="/Company"
            onClick={() => {
              this.props.selectedCompanyNameProps("");
              this.props.showHideListProp(true);
            }}
          >
            <span className="font-icon">&#104;</span>Companies
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            onClick={() => {
              this.props.showHideListSub(true);
            }}
            to="/Subscription"
          >
            <span className="font-icon">&#68;</span>Subscriptions
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            onClick={() => {
              this.props.showHideListUser(true);
            }}
            to="/Users"
          >
            <span className="font-icon">&#71;</span>Users
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            to="/Groups"
            onClick={() => {
              this.props.showHideGroup(false);
            }}
          >
            <span className="font-icon">&#112;</span>Groups
          </NavLink>
        </li>
        <li className="sidenav-item">
          <NavLink
            activeClassName="active"
            className="sidenav-link"
            onClick={() => {
              this.props.showHideListNumbers(true);
            }}
            to="/Numbers"
          >
            <span className="font-icon">&#119;</span>Numbers
          </NavLink>
        </li>
        <li className="sidenav-divider mb-1"></li>
        <div
          className="ps__rail-x"
          style={{
            left: "0px",
            bottom: "0px",
          }}
        >
          <div
            className="ps__thumb - x"
            style={{ left: "0px", width: "0px" }}
          ></div>
        </div>
        <div className="ps__rail-y" style={{ top: "0px", right: "4px" }}>
          <div
            className="ps__thumb-y"
            style={{
              top: "0px",
              height: "0px",
            }}
          ></div>
        </div>
      </ul>
    );
  }
  public render() {
    return (
      <React.Fragment>
        <div
          id="layout-sidenav"
          className={
            this.props.toggleClass === true
              ? "toggleClass layout-sidenav sidenav sidenav-vertical bg-sidenav-theme"
              : "layout-sidenav sidenav sidenav-vertical bg-sidenav-theme"
          }
        >
          {this.props.roleNameProps === 0
            ? this.companyRole()
            : this.props.roleNameProps === -10
            ? this.superAdminRole()
            : this.props.roleNameProps === 20
            ? this.userRole()
            : this.props.roleNameProps === 5
            ? this.partnerRole()
            : this.userRole()}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (props: any) => {
  return {
    roleName: props.characterReducer.RoleNameReducer,
    toggleClass: props.characterReducer.toggleClassSidNavReducer,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    showHideListProp: (value: boolean) => {
      dispatch(action.showHideListCompany(value));
    },
    showHideListSub: (value: boolean) => {
      dispatch(action.showHideList(value));
    },
    selectedCompanyNameProps: (selectCompany: string) => {
      dispatch<any>(action.showHideListGroups(false));

      dispatch(action.displaySelectedCompanyName(selectCompany));
    },
    showHideGroup: (value: any) => {
      dispatch<any>(action.showHideListGroups(false));
      dispatch<any>(action.hideGroupNonMemberTab(false));
      dispatch<any>(action.hideGroupEmailHeading(true));
      dispatch<any>(action.saveSelectedComapnyDetail({}));
      //#3804 maksud
      //dispatch<any>(action.getGroupListByCompanyAndSubscriptionId(null, null, ""));
    },

    showHideListUser: (value: boolean) => {
	 //#3804 Maksud
      //dispatch(action.showHideListUser(value));
    },
    showHideListNumbers: (value: boolean) => {
      //#3804 maksud
      //dispatch<any>(action.MobileNumberListTwillio([]));
      //dispatch<any>(action.localNumberListTwillio([]));
      //dispatch(action.showHideNumberList(value));
      //#3804 maksud
      //dispatch(action.getPhoneNumerList(null, "All"));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideBarComponent);
