
import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

// Create the containers interface

class ConfirmationDeleteComponent extends React.Component<any, any> {
    constructor(props: any, state: any) {
        super(props)

    }
    componentDidMount(){
    }
  renderHtml() {
    return (
      <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title"  id="exampleModalLabel">Confirmation</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      Are you sure you want to Delete ? 
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default cancelBtn" data-dismiss="modal">Cancel</button>
        <button type="button" className="btn btn-primary " data-dismiss="modal" onClick={() => { this.props.deleteSucces() }}>Delete</button>
      </div>
    </div>
  </div>
</div>
    );
  }
  renderDeleteConfirmation() {
    return (
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title"  id="exampleModalLabel">Confirmation</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      Are you sure you want to Delete this number ? 
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default cancelBtn" data-dismiss="modal">Cancel</button>
        <button type="button" className="btn btn-primary " data-dismiss="modal" onClick={() => { this.props.deleteSucces() }}>Delete</button>
      </div>
    </div>
  </div>
    );
  }
    public render() {
        return (
            <React.Fragment>
            {this.props.confimationMessage !== '' && this.props.confimationMessage !== undefined  ? 
            this.renderDeleteConfirmation()
            :
          this.renderHtml()
          
          } 

            </React.Fragment>
        );
    }
};


const mapStateToProps = (props: any) => {
    return {
        errorOccured: props.characterReducer.errorOccured,
        savedSuccessfull: props.characterReducer.dataSaved,
        deletedMessage: props.characterReducer.deletedMessgae
    };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
    return {
       
    }
}
export default connect(mapStateToProps,
    mapDispatchToProps)(ConfirmationDeleteComponent);