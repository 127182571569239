import * as React from "react";
import HeaderComponent from "./components/layoutComponents/headersComponent";
import SideBarComponent from "./components/layoutComponents/sideNavComponent";
import { Router, Route } from "react-router-dom";
import Routes from "./Routes";
//import UserParentComponent from './components/userComponent/usersParentComponent';
import IdleTimer from "react-idle-timer";
import IdleComponent from "./components/idleComponent/idleComponent";
//import history from './history';
import ErrorPageComponent from "./components/ErrorPageComponent/ErrorPageComponent";
import FooterComponent from "./components/layoutComponents/footerComponent";
import { authContext } from "./adalConfig";
class Layout extends React.Component<any, any> {
  idleTimer: any;
  onIdle: any;
  constructor(props: any) {
    super(props);
    this.state = {
      loggedinUserAccessLevel: "",
      timeout: 1000 * 1500 * 1,
      userLoggedIn: false,
      isTimedOut: false,
    };
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }
  componentDidMount() {
    if (this.props.roleName === undefined) {
      this.props.getLoggedInAccessLevel();
    }
  }

  _onIdle(e: any) {
    this.setState({ isTimedOut: true });
    //  adalConfig.expireOffsetSeconds=1000 * 9 * 1,
    authContext.clearCache();
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.roleName !== prevState.loggedinUserAccessLevel) {
      return {
        loggedinUserAccessLevel: nextProps.roleName,
      };
    } else return null;
  }
  pagesHtml = () => {
    return (
      <Router history={this.props.history}>
        <HeaderComponent />
        <div className="layout-wrapper layout-2">
          <div className="layout-inner">
            <SideBarComponent roleNameProps={this.props.roleName} />
            <div className="layout-container">
              <Routes permissions={this.props.roleName} />
              <FooterComponent />
            </div>
          </div>
        </div>
      </Router>
    );
  };
  userRoleRoutes = () => {
    return this.props.disabledUserLoggedIn === true ? (
      <Router history={this.props.history}>
        <Route component={ErrorPageComponent} />
      </Router>
    ) : this.props.history.location.pathname === "/IdlePage" ||
      this.state.isTimedOut === true ? (
      <Router history={this.props.history}>
        <Route component={IdleComponent} />
      </Router>
    ) : window.location.pathname === "/" ||
      this.props.history.location.pathname === "/MyProfile" ||
      this.props.history.location.pathname === "/MyCompany" ||
      this.props.history.location.pathname === "/ErrorPage" ||
      this.props.history.location.pathname === "/Dashboards" ? (
      this.pagesHtml()
    ) : this.props.history.location.pathname === "/Numbers" ||
      this.props.history.location.pathname === "/Inbox" ||
      this.props.history.location.pathname === "/Company" ||
      this.props.history.location.pathname === "/Providers" ||
      this.props.history.location.pathname === "/Subscription" ||
      this.props.history.location.pathname === "/Groups" ||
      this.props.history.location.pathname === "/Users" ? (
      <Router history={this.props.history}>
        <Route component={ErrorPageComponent} />
      </Router>
    ) : this.props.history.location.pathname === "/IdlePage" ||
      this.state.isTimedOut === true ? (
      <Router history={this.props.history}>
        <Route component={IdleComponent} />
      </Router>
    ) : (
      this.pagesHtml()
    );
  };

  companyRoleRoutes = () => {
    return this.props.disabledUserLoggedIn === true ? (
      <Router history={this.props.history}>
        <Route component={ErrorPageComponent} />
      </Router>
    ) : this.props.history.location.pathname === "/IdlePage" ||
      this.state.isTimedOut === true ? (
      <Router history={this.props.history}>
        <Route component={IdleComponent} />
      </Router>
    ) : window.location.pathname === "/" ||
      this.props.history.location.pathname === "/MyProfile" ||
      this.props.history.location.pathname === "/MyCompany" ||
      this.props.history.location.pathname === "/ErrorPage" ||
      this.props.history.location.pathname === "/Dashboards" ||
      this.props.history.location.pathname === "/Subscription" ||
      this.props.history.location.pathname === "/Groups" ||
      this.props.history.location.pathname === "/Users" ||
      this.props.history.location.pathname === "/Help_Center" ? (
      this.pagesHtml()
    ) : this.props.history.location.pathname === "/Numbers" ||
      this.props.history.location.pathname === "/Inbox" ||
      this.props.history.location.pathname === "/Company" ||
      this.props.history.location.pathname === "/Providers" ? (
      <Router history={this.props.history}>
        <Route component={ErrorPageComponent} />
      </Router>
    ) : this.props.history.location.pathname === "/IdlePage" ? (
      <Router history={this.props.history}>
        <Route component={IdleComponent} />
      </Router>
    ) : (
      this.pagesHtml()
    );
  };

  superAdminRoleRoutes = () => {
    return this.props.history.location.pathname === "/IdlePage" ||
      this.state.isTimedOut === true ? (
      <Router history={this.props.history}>
        <Route component={IdleComponent} />
      </Router>
    ) : window.location.pathname === "/" ||
      this.props.history.location.pathname === "/MyProfile" ||
      this.props.history.location.pathname === "/MyCompany" ||
      this.props.history.location.pathname === "/ErrorPage" ||
      this.props.history.location.pathname === "/Numbers" ||
      this.props.history.location.pathname === "/Dashboards" ||
      this.props.history.location.pathname === "/Company" ||
      this.props.history.location.pathname === "/Subscription" ||
      this.props.history.location.pathname === "/Groups" ||
      this.props.history.location.pathname === "/Users" ||
      this.props.history.location.pathname === "/Inbox" ||
      this.props.history.location.pathname === "/Help_Center" ? (
      this.pagesHtml()
    ) : (
      <Router history={this.props.history}>
        <Route component={ErrorPageComponent} />
      </Router>
    );
  };
  partnerRoleRoutes = () => {
    return this.props.disabledUserLoggedIn === true ? (
      <Router history={this.props.history}>
        <Route component={ErrorPageComponent} />
      </Router>
    ) : this.props.history.location.pathname === "/IdlePage" ||
      this.state.isTimedOut === true ? (
      <Router history={this.props.history}>
        <Route component={IdleComponent} />
      </Router>
    ) : window.location.pathname === "/" ||
      this.props.history.location.pathname === "/MyProfile" ||
      this.props.history.location.pathname === "/MyCompany" ||
      this.props.history.location.pathname === "/ErrorPage" ||
      this.props.history.location.pathname === "/Numbers" ||
      this.props.history.location.pathname === "/Dashboards" ||
      this.props.history.location.pathname === "/Company" ||
      this.props.history.location.pathname === "/Subscription" ||
      this.props.history.location.pathname === "/Groups" ||
      this.props.history.location.pathname === "/Users" ||
      this.props.history.location.pathname === "/Help_Center" ? (
      this.pagesHtml()
    ) : this.props.this.props.history.location.pathname === "/IdlePage" ? (
      <Router history={this.props.history}>
        <Route component={IdleComponent} />
      </Router>
    ) : (
      <Router history={this.props.history}>
        <Route component={ErrorPageComponent} />
      </Router>
    );
  };
  public render() {
    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          debounce={0}
          timeout={this.state.timeout}
        />

        {this.state.loggedinUserAccessLevel !== undefined ? (
          this.state.loggedinUserAccessLevel === 20 ? (
            this.userRoleRoutes()
          ) : this.state.loggedinUserAccessLevel === 0 ? (
            this.companyRoleRoutes()
          ) : this.state.loggedinUserAccessLevel === -10 ? (
            this.superAdminRoleRoutes()
          ) : this.state.loggedinUserAccessLevel === 5 ? (
            this.partnerRoleRoutes()
          ) : (
            this.userRoleRoutes()
          )
        ) : (
          <Router history={this.props.history}>
            <HeaderComponent />
            <FooterComponent />
          </Router>
        )}
      </React.Fragment>
    );
  }
}

export default Layout;
