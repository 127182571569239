import * as React from 'react';
import './App.css';
import { ThunkDispatch } from 'redux-thunk';
import * as action from './redux/actions/actions';
import Layout from './Layout';
import { connect } from 'react-redux';
import {getLoggedInUSerName} from './adalConfig';
import history from './history';
 class App extends React.Component<any, any> {   
  constructor(props: any) {
  super(props)
  this.state = {
      loggedinUserAccessLevel:'',
      roleNameState:"",
      disbaledUser:false,
  }

}
componentDidMount() {
  var userData = getLoggedInUSerName();
  if(userData !== null){
    this.props.getloggedInUserDetail(userData.userName);
    // TASK 3804 // Maksud
    //this.props.getAccessLevel(userData.userName);
}

}
static getDerivedStateFromProps(nextProps: any, prevState: any) {
  if ( nextProps.loggedinUserAccessLevel !== prevState.loggedinUserAccessLevel  ||
    nextProps.loggedInUserDisable !== prevState.disbaledUser
    ) {
    return {
      loggedinUserAccessLevel:nextProps.loggedinUserAccessLevel ,
      disbaledUser:nextProps.loggedInUserDisable
     };
  }
  else return null;
}
public render() {
  return (

    <div className="App">{
     ( this.state.loggedinUserAccessLevel !== undefined || this.state.loggedinUserAccessLevel !== '')?
     
     <Layout  history={history}
     roleName={this.state.loggedinUserAccessLevel} 
            disabledUserLoggedIn={this.state.disbaledUser}
              getLoggedInAccessLevel = {()=>{
                this.props.getAccessLevel()}} 
                />
      :
      null
    }
     
    </div>

  );
}
};
const mapStateToProps = (props: any) => {
  return {
      loggedinUserAccessLevel: props.characterReducer.loggedinUserAccessLevel,
      showHideListCompany: props.characterReducer.showHideListCompanyReduc,
      editData: props.characterReducer.loggedInUserDetailReducer,
      loggedInUserDisable: props.characterReducer.loggedInUserDisableReducer

    }
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
      getAccessLevel: (email:any) => {        
            dispatch<any>(action.getAccessLeveApi(email))
        },
      getloggedInUserDetail: (email: boolean) => {
         dispatch(action.loggedInUserEmail(email))
    },
    fetchAllServiceProviders: () => {
      dispatch(action.fetchAllServiceProviders())
  }
  }
}
export default connect(mapStateToProps,
  mapDispatchToProps)(App);
