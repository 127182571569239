import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as action from '../../redux/actions/actions';
import history from '../../history';
import { CompanyListComponent } from './companyListComponent';
import NotificationComponent from '../sharedComponents/displayNotifications';
import { TabCompanyComponent } from './tabCompanyComponent';
import GroupParentComponent from '../groupsComponent/groupParentComponent';
class CompanyParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props)
    this.state = {
      companyList: this.props.companyList,
      calledFrom: "",
      editDetail: '',
      ActiveTab: "userList",
      showAddCompanyDialog: false,
      errorOccured: '',
      showHideListCompany: true,
      hideCompanyTabState: true,
      editCompanyData: {},
      showUserAdd: false,
      showGroupAdd: false,
      myCompanyID:'',
      searchBox:'',
      filterCompanyData:[],
      rowActiveId:""


    }

  }

  componentDidMount() {
    var queryParam = this.props.location !== null && this.props.location !== undefined ? this.props.location.state !== null && this.props.location.state !== undefined ?
      this.props.location.state.callingFrom !== null && this.props.location.state.callingFrom !== undefined ?
        this.props.location.state.callingFrom
        : "Company"
      : "Company"
      : "Company";
    if (queryParam !== "Company" && queryParam !== "") {
      history.replace('/Company', { callingFrom: "" });
      this.setState({
        calledFrom: 'edit',
      })
      this.breadCrumbNavigationFromNumbers(queryParam)
    }
    else {
      this.companyCalled()
    }

  }
  companyCalled() {
    this.props.rowActiveIdCompany("");
    this.props.selectedCompanyNameProps("");
    this.props.dispatchActiveTabName("userList");
    this.props.showHideListProp(true);
    this.props.hideGroupNameHeading(true)
    this.props.getCompanyListProp();
    this.props.setSearchBoxValue('');
    this.props.setSearchBoxForOtherComponents('');

  }
  breadCrumbNavigationFromNumbers = (queryParam: any) => {
    if (queryParam === "Company:add") {
      this.props.hideUserTabFromCompanyDetail(true)
      this.props.showHideListProp(false);
    }
    else if (queryParam === "Company:edit") {
      this.props.hideUserTabFromCompanyDetail(true)
      this.props.showHideListProp(false);
    }
    else if (queryParam === "Company:editGroup" || queryParam === "Company:addGroup") {
      this.props.hideGroupTabFromCompanyDetail(false)
      this.props.dispatchActiveTabName("groupList");

    }
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.errorOccured !== prevState.errorOccured ||
      nextProps.showHideListCompany !== prevState.showHideListCompany ||
      nextProps.hideCompanyTab !== prevState.hideCompanyTabState ||
      nextProps.activeTabName !== prevState.ActiveTab ||
      nextProps.editCompanyDetail !== prevState.editCompanyData ||
      nextProps.hideUserTab !== prevState.showUserAdd ||
      nextProps.hideGroupTab !== prevState.showGroupAdd ||
      nextProps.MyCompanyId !== prevState.myCompanyID ||
      nextProps.searchBoxText !== prevState.searchBox ||
      nextProps.filterCompanyList !== prevState.filterCompanyData ||
      nextProps.activeCompanyId !==prevState.rowActiveId
    ) {
      return {
        searchBox:nextProps.searchBoxText ,
        myCompanyID:nextProps.MyCompanyId,
        showGroupAdd: nextProps.hideGroupTab,
        showUserAdd: nextProps.hideUserTab,
        ActiveTab: nextProps.activeTabName,
        hideCompanyTabState: nextProps.hideCompanyTab,
        showHideListCompany: nextProps.showHideListCompany,
        errorOccured: nextProps.errorOccured,
        editCompanyData: nextProps.editCompanyDetail,
        filterCompanyData:nextProps.filterCompanyList,
        rowActiveId:nextProps.activeCompanyId
      };
    }
    else return null;
  }

  filterBySelectedStatus = (filterByStatus: any) => {
    let filteredList: any;
    if (filterByStatus === 'disabled') {
      filteredList = this.props.groupListByCompAndSubIdAction.filter((x: any) => x.Disabled === true);
      this.props.dispatchGetGroupListFilter(filteredList)
    }
    else if (filterByStatus === 'enabled') {
      filteredList = this.props.groupListByCompAndSubIdAction.filter((x: any) => x.Disabled === false);
      this.props.dispatchGetGroupListFilter(filteredList)
    }

    else {
      this.props.dispatchGetGroupListFilter(this.props.groupListByCompAndSubIdAction)
    }
  }
  navigationFromCompany = () => {
    if (this.props.userAddCalledFromCompanyTab === true) {
      this.props.showHideListUserProp(true)
      this.props.addUserCalledFromCompanyTab(false)
    }
    else if (this.props.subscriptionAddCalledFromCompany === true) {
      this.props.showHideListSubscriptionProp(true)
      this.props.addSubscriptionCalledFromCompanyTab(false)
    }
    else if (this.props.groupAddCalledReducer === true) {
      this.props.groupAddCalledFromCompanyTab(false)
      this.props.hideGroupListProps(false)
    }
    else {
      this.showCompanyListTab()
    }
  }
  showCompanyListTab() {
    this.props.showHideListProp(true);
    this.props.selectedCompanyNameProps("");
    this.setState({
      calledFrom: "noEdit"
    })
  }
  public render() {
    return (
      <React.Fragment>
        <div className="layout-content">
          <NotificationComponent />

          <div className="container-fluid">
            {
              this.state.hideCompanyTabState === false ? null :
                this.state.calledFrom === "edit" && this.props.selectedCompanyName !== "" ?
                  <h2 className="companyName  text-left">
                    <span className="iconNavigation cursor_pointer" onClick={() => this.navigationFromCompany()}>
                      <i className="fa fa-chevron-left cursor_pointer" aria-hidden="true" ></i></span>
                    Company  /  <b>{this.props.selectedCompanyName}</b>
                  </h2>
                  :
                  this.state.calledFrom === "add" && this.props.showHideListCompany === false ?
                    <h2 className="companyName  text-left ">Add Company </h2>
                    :
                    null
            }
            {
              this.state.showHideListCompany === true ?
                this.props.pendingCompanyList === false ?
                  (this.state.errorOccured !== undefined && this.state.errorOccured !== '' && this.state.errorOccured.split(':')[1] === "Company") ?
                    <div className="container-fluid flex-grow-1 container-p-y">
                      {this.state.errorOccured.split(':')[0]}
                    </div>
                    :
                    <CompanyListComponent
                      companyList={this.props.companyList}
                      openAddUpdateWindow={(object: any) => {
                        this.props.hideUserTabFromCompanyDetail(false)
                        this.props.hideGroupTabFromCompanyDetail(false)
                        this.props.dispatchActiveTabName("userList")
                        this.props.saveSelectedCompanyDetail(object)
                        this.setState({
                          calledFrom: 'edit',
                          editDetail: object,
                          activeTab: 'userList',
                          hideOtherTabs: false
                        })
                        this.props.selectedCompanyNameProps(object.Name)
                        this.props.showHideListProp(false)
                     }}
                      showHideListComp={(value: any) => {
                        this.props.showHideListProp(false)
                        this.props.dispatchActiveTabName("companyEdit")
                        this.setState({ 
                          calledFrom: 'add', 
                          hideOtherTabs: true, 
                          showAddCompanyDialog: true, 
                          activeTab: 'companyEdit' })
                          this.props.saveSelectedCompanyDetail({})

                      }}
                      saveEditDtail={(object: any) => {
                        this.props.saveSelectedCompanyDetail(object)

                      }}
                      searchTextBox={this.state.searchBox}
                      setSearchBox={(value:any)=>this.props.setSearchBoxValue(value)}
                      filterationByTxt={(list:any)=>
                        this.props.getCompanyList(list)
                    }
                    activeCompanyId={this.state.rowActiveId}
                    rowActiveIdCompanyProps={(id:any)=>this.props.rowActiveIdCompany(id)}
                    filterCompanyList = {this.state.filterCompanyData}
                    />
                  :
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>

                :
                this.state.hideCompanyTabState === false ?
                  <GroupParentComponent
                    companyId={this.state.editDetail.Id}
                    showGroupNonMember={true}
                    selectedCompanyName={this.props.selectedCompanyName}

                  />
                  :
                  <TabCompanyComponent
                  searchTextBox={this.state.searchBox}

                    showHideListPropTab={(value: any) => {
                      this.props.showHideListProp(value);
                      this.props.selectedCompanyNameProps('')
                    }}
                    hideUserListTab={(value: any) => {
                      this.props.hideUserTabFromCompanyDetail(value)
                    }}
                    hideGroupListTab={(value: any) => {
                      this.props.hideGroupTabFromCompanyDetail(value)

                    }}
                    parentCompanyList={this.props.parentCompanyList}
                    showUserAddTab={this.state.showUserAdd}
                    showGroupAddTab={this.state.showGroupAdd}
                    activeTab={this.state.ActiveTab}
                    hideOtherTabs={this.state.hideOtherTabs}
                    editCompanyCalledTab={this.state.calledFrom}
                    userListPending={this.props.userListPending}
                    editCompany={this.state.editCompanyData}
                    addUpdatedCompanyTabs={(type: string, data: {}) => {
                      this.props.addUpdatedCompanyProps(type, data);

                    }}
                    deleteCompanyByIdTab={(companyId: any) => {
                      this.props.deleteCompanyById(companyId);
                    }}
                    ///ShowGroupNonMember tab
                    hideTabs={this.state.hideCompanyTabState}
                    getParentCompany={() => {
                      this.props.getParentCompaniesListAction()
                    }}
                    myCompanyId={this.state.myCompanyID}
                    loggedInUserRole={this.props.loggedinUserAccessLevel}
                    dispatchActiveTabName={(value: any) => { this.props.dispatchActiveTabName(value) }}
                    setBackNavigationOnTabPress={(value:any)=>{
                      this.props.addUserCalledFromCompanyTab(value)
                      this.props.addSubscriptionCalledFromCompanyTab(value)
                      this.props.groupAddCalledFromCompanyTab(value)


                    }}
                  />

            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    userListPending: props.characterReducer.pendingUserList,
    showHideListCompany: props.characterReducer.showHideListCompanyReduc,
    companyList: props.characterReducer.companyList,
    errorOccured: props.characterReducer.errorListMessage,
    savedSuccessfull: props.characterReducer.dataSaved,
    pendingCompanyList: props.characterReducer.pendingCompanyList,
    selectedCompanyName: props.characterReducer.selectedCompanyName,
    hideCompanyTab: props.characterReducer.hideCompanyTabReducer,
    activeTabName: props.characterReducer.activeTabNameReducer,
    parentCompanyList: props.characterReducer.parentCompanyListReducer,
    loggedinUserAccessLevel: props.characterReducer.loggedinUserAccessLevel,
    editCompanyDetail: props.characterReducer.savedCompanyDetailReducer,
    hideUserTab: props.characterReducer.hideUserTabCompanyDetailReducer,
    hideGroupTab: props.characterReducer.hideGroupTabCompanyDetailReducer,
    subscriptionAddCalledFromCompany: props.characterReducer.subscriptionAddCalledFromCompanyTabReducer,
    groupAddCalledReducer: props.characterReducer.groupAddCalledFromCompanyTabReducer,
    userAddCalledFromCompanyTab: props.characterReducer.userAddCalledFromCompanyTabReducer,
    MyCompanyId:props.characterReducer.myCompanyPartnerParentReducer,
    searchBoxText:props.characterReducer.searchTextBoxReducer,
    filterCompanyList:props.characterReducer.filterCompanyListReducer,
    activeCompanyId:props.characterReducer.rowActiveIdCompanyReducer



  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    setSearchBoxForOtherComponents:(value:any) =>{
      dispatch(action.searchBox_Value_In_Subscription(value))
      dispatch(action.searchBox_Value_In_Groups(value))
      dispatch(action.searchBox_Value_In_Users(value))
  },
  removeAllActieId:()=>{
    dispatch(action.rowActiveId_Groups(''));
    dispatch(action.rowActiveId_Numbers(''));
    dispatch(action.rowActiveId_Subscription(''));
    dispatch(action.rowActiveId(''));
},
    setSearchBoxValue:(value:any)=>{
      dispatch(action.searchBox_Value_InTables(value))
  },
    showHideListSubscriptionProp: (value: boolean) => {
      dispatch(action.showHideList(value))
    },
    rowActiveIdCompany:(id:any)=>{
      dispatch(action.rowActiveIdCompany(id))
    },
    dispatchActiveTabName: (value: any) => {
      dispatch(action.displayActiveTabName(value))
    },
    getCompanyList:(list:any)=>{
      dispatch(action.getCompanyList_Filter(list))
  },
    
    getCompanyListProp: () => {
      dispatch(action.hideCompanyTab(true))

      dispatch(action.pendingCompanyList(true))
      dispatch(action.getCompanyList('',[],''));
    },
    selectedCompanyNameProps: (selectCompany: string) => {
      dispatch(action.displaySelectedCompanyName(selectCompany));

    },
    hideGroupNameHeading: (value: string) => {
      dispatch(action.hideGroupEmailHeading(value));

    },
    showHideListSubsProp: (value: any) => {
      dispatch(action.showHideListCompany(value))
    },
    addUpdatedCompanyProps: (type: string, object: {}) => {
      dispatch(action.addUpdateCompany(type, object,"companyPage"));
    },
    showHideListProp: (value: boolean) => {
      dispatch(action.showHideListCompany(value))
    },

    deleteCompanyById: (companyId: string) => {
      dispatch(action.deleteCompanyByCompanyId(companyId))
    },
    getParentCompaniesListAction: () => {
      dispatch(action.getParentCompaniesList())
    },
    saveSelectedCompanyDetail: (oject: any) => {
      dispatch(action.saveSelectedComapnyDetail(oject))
    },
    hideUserTabFromCompanyDetail: (value: any) => {
      dispatch(action.hideUserTabFromCompanyDetail(value))
    },
    hideGroupTabFromCompanyDetail: (value: any) => {
      dispatch(action.hideGroupTabFromCompanyDetail(value))
    },
    showHideListUserProp: (value: boolean) => {
      dispatch(action.showHideListUser(value))
    },
    userEditTabHeading: (value: any) => {
      dispatch(action.userEditFromNumberBreadCrumb(value))
    },
    addUserCalledFromCompanyTab: (value: any) => {
      dispatch<any>(action.userAddCalledFromCompanyTab(value))
    },
    addSubscriptionCalledFromCompanyTab: (value: any) => {
      dispatch<any>(action.subscriptionAddCalledFromCompanyTab(value))
    },
    groupAddCalledFromCompanyTab: (value: any) => {
      dispatch<any>(action.groupsAddCalledFromCompanyTab(value))
    },
    hideGroupListProps: (value: any) => {
      dispatch<any>(action.showHideListGroups(value))
    },

  }
}
export default connect(mapStateToProps,
  mapDispatchToProps)(CompanyParentComponent);