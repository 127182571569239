import * as React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { CompanyAddUpdateComponent } from './companyAddUpdateComponent';
import SubscriptionParentComponent from '../subscriptionComponent/subscriptionParentComponent';
import UsersComponent from '../userComponent/usersParentComponent';
import GroupParentComponent from '../groupsComponent/groupParentComponent';
export class TabCompanyComponent extends React.Component<any, any> {
    constructor(props: any, state: any) {
        super(props)
        this.state = {
            ActiveTab: this.props.activeTab,
            hideTabsClass: this.props.hideTabs,
            showSubscriptionData: false,
            showGroupData: false,
            showUserData: true,
            disableTabs: false,
            parentCompanyListState: [],
            editCompanyTab: {},
            showUserEdit: false,
            showGroupTab: false,
            myCompanyID:'',
            calledFrom_CompanyTab:""
         }

    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
          if (
            nextProps.errorOccured !== prevState.errorOccured ||
            nextProps.showHideListCompany !== prevState.showHideListCompany ||
            nextProps.hideCompanyTab !== prevState.hideTabsClass ||
            nextProps.hideOtherTabs !== prevState.disableTabs ||
            nextProps.parentCompanyList !== prevState.parentCompanyListState ||
            nextProps.editCompany !== prevState.editCompanyTab ||
            nextProps.showUserAddTab !== prevState.showUserEdit ||
            nextProps.showGroupAddTab !== prevState.showGroupTab ||
            nextProps.activeTab !== prevState.ActiveTab ||
            nextProps.myCompanyId !== prevState.myCompanyID
        ) {
            return {
                ActiveTab: nextProps.activeTab,
                showGroupTab: nextProps.showGroupAddTab,
                showUserEdit: nextProps.showUserAddTab,
                editCompanyTab: nextProps.editCompany,
                parentCompanyListState: nextProps.parentCompanyList,
                disableTabs: nextProps.hideOtherTabs,
                hideTabsClass: nextProps.showHideListCompany,
                myCompanyID: nextProps.myCompanyId

            };
        }
        else return null;
    }
    public render() {
        return (
            <React.Fragment>
                <Tabs className="tabGroupMember" defaultActiveKey={this.state.ActiveTab}
                   onSelect={(currentTabName: any) => {
                        this.props.dispatchActiveTabName(currentTabName)
                        this.props.setBackNavigationOnTabPress(false)
                        if (this.state.ActiveTab === "subscriptionList") {
                            this.setState({
                                showSubscriptionData: true
                            })
                        }
                        else if (this.state.ActiveTab === "groupList") {
                        this.props.hideGroupListTab(false)

                            this.setState({
                                showGroupData: true
                            })

                        } else if (this.state.ActiveTab === "userList") {
                        this.props.hideUserListTab(false)

                            this.setState({
                                showUserData: true
                            })
                        }

                    }}
                    id="uncontrolled-tab-example">

                    <Tab eventKey="userList" title={<span><span className="font-icon">&#71;</span> Users</span>} disabled={this.state.disableTabs}>
                        {
                              this.state.ActiveTab === "userList"?
                                this.props.activeTab === 'companyEdit' ?
                                    <div className="noUser_found">
                                        No Users found
                                      </div>
                                    :

                                 <UsersComponent
                                        companyId={this.state.editCompanyTab.Id}
                                        showEditTab={this.state.showUserEdit}
                                    />
                                :
                                null
                        }

                    </Tab>


                    <Tab eventKey="groupList" title={<span><span className="font-icon">&#112;</span> Groups</span>} disabled={this.state.disableTabs}>
                        {

                            this.state.ActiveTab === "groupList" ? 
                                this.props.activeTab === 'companyEdit' ?
                                    <div className="noUser_found">
                                        No Groups found
                                      </div>
                                    :
                                    <GroupParentComponent
                                        companyId={this.state.editCompanyTab.Id}
                                        showGroupEditTab={this.state.showGroupTab}

                                    />
                        

                                :
                                null 
                        }



                    </Tab>
                    <Tab eventKey="subscriptionList" title={<span><span className="font-icon">&#68;</span> Subscriptions</span>} disabled={this.state.disableTabs}>
                        {
                           this.state.ActiveTab === "subscriptionList" ?
                                this.props.activeTab === 'companyEdit' ?
                                    <div className="noUser_found">
                                        No Subscriptions found
                                                   </div>
                                    :
                                    <SubscriptionParentComponent
                                        companyId={this.state.editCompanyTab.Id}
                                        calledFrom={this.state.editCompanyTab !== '' ? 'editCompany' : 'addCompany'}
                                        showGroupMemberNonMemberTab={(value: any) => {
                                            this.props.showGroupNonMemberTab(value)
                                        }}
                                        isParentCompany={this.state.editCompanyTab.IsParent}
                                    />
                                : null

                        }
                    </Tab>

                    <Tab eventKey="companyEdit" title={<span><span className="font-icon">&#67;</span> Company Detail</span>}>
                        <CompanyAddUpdateComponent
                            calledFrom={this.props.editCompanyCalledTab}
                            editData={this.state.editCompanyTab}
                            deleteCompany={(companyId: string) => { this.props.deleteCompanyByIdTab(companyId); }}
                            addUpdateCompanyProp={(type: string, data: {}) => {
                                this.props.addUpdatedCompanyTabs(type, data);

                            }}
                            changeState={() => {
                                this.props.showHideListPropTab(true)
                            }}
                            parentCompanyList={this.state.parentCompanyListState}
                            getParentCompanyList={() => { this.props.getParentCompany() }}
                            loggedInUserRole={this.props.loggedInUserRole}
                            myCompanyId={this.state.myCompanyID}
                        />

                    </Tab>

                </Tabs>

            </React.Fragment>
        );
    }
}
