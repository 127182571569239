import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { UserEditComponent } from "./userEditComponent";
import * as action from "../../redux/actions/actions";
import NotificationComponent from "../sharedComponents/displayNotifications";
import { UserListComponent } from "./userListComponent";
import history from "../../history";
// Create the containers interface
class UsersComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      editDetail: {},
      type: "",
      userList: this.props.filteringUserProp,
      selectedSearch: "status_all",
      companyId: "",
      subscriptionList: [],
      errorOccured: "",
      uniqueNumber: "",
      emailForAddEditUser: "",
      nameForAddEditUser: "",
      subscriptionForAddEditUser: "",
      subscriptionNameForAddEditUser: "",
      uniqueNumberForAddEditUser: "",
      creditForAddEditUser: "",
      roleIdForAddEditUser: "",
      storeTextForAddEditUser: "",
      disableForAddEditUser: "",
      ContactLookupEnabledForAddEditUser:"",
      editUserObj: "",
      defaultCountryCode: "",
      registerationDateForAddEditUser: "",
      usedCreditForAddEditUser: "",
      userEditText: "",
      unAssignedNumbers: [],
      pendingUnAssignNumber: false,
      searchTextBox: "",
      whiteLabelText: "",
      rowActiveId: "",
      emailEnabled: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }
  shouldComponentRender() {
    const { userListPending } = this.props;
    if (userListPending === false) {
      return false;
    }
    // more tests
    return true;
  }
  componentDidMount() {
    ///Check the location of  calling component
    var calledFrom =
      this.props.location && this.props.location
        ? this.props.location.state !== null && this.props.location.state
          ? this.props.location.state.callingFrom &&
            this.props.location.state.callingFrom
            ? this.props.location.state.callingFrom
            : "Users"
          : "Users"
        : "Users";
    if (calledFrom !== "Users" && calledFrom !== "") {
      history.replace("/Users", { callingFrom: "" });
      this.setAddEdit(calledFrom);      

    } else {
      var companyId = "";
      if (this.props.companyId && this.props.companyId) {
        companyId = this.props.companyId;
        this.setState({ companyId: companyId });
      }
      //Called From breadcrumb  inside company tab
      if (this.props.showEditTab && this.props.showEditTab) {

        //#3804 Maksud
        this.props.getSubscriptionBycompId(companyId);

        this.showEditTabTrue(companyId);
      } else {
        this.props.setSearchBoxValue("");
        this.breadCrumbNavigation(companyId);
      }
    }
    //this.state.uniqueNumber && console.log(this.state.uniqueNumber);
  }

  showEditTabTrue = (companyId: any) => {
    if (this.props.showEditTab === true) {
      if (this.state.userEditText === "edit") {
        this.setState({
          type: "edit",
        });
      } else {
        this.setState({
          type: "add",
        });
      }
      this.props.showHideListProp(false);
    } else {
      this.breadCrumbNavigation(companyId);
    }
  };
  setAddEdit(calledFrom: any) {
    if (calledFrom === "EditUsers") {
      this.setState({
        type: "edit",
      });
    } else {
      this.setState({
        type: "add",
      });
    }
  }
  breadCrumbNavigation = (companyId: any) => {
    this.props.showHideListProp(true);
    this.props.rowActiveId("");
    this.props.getUserListprops(null, companyId);
    
    //#3804 Maksud
    //this.props.getSubscriptionBycompId(companyId);
    this.props.uniqueNumberProps("");
    this.emptyObject("sart");
  };

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.errorOccured !== prevState.errorOccured ||
      nextProps.filteringUserProp !== prevState.userList ||
      nextProps.subscriptionList !== prevState.subscriptionList ||
      nextProps.uniqueNumberProp !== prevState.uniqueNumber ||
      nextProps.emailAddEditUserProps !== prevState.emailForAddEditUser ||
      nextProps.nameAddEditUserProps !== prevState.nameForAddEditUser ||
      nextProps.subscriptionAddEditUserProps !==
        prevState.subscriptionForAddEditUser ||
      nextProps.subscriptionNameAddEditUserProps !==
        prevState.subscriptionNameForAddEditUser ||
      nextProps.uniqueNumberCostAddEditUserProps !==
        prevState.uniqueNumberForAddEditUser ||
      nextProps.creditLimitForAddEditUserProps !==
        prevState.creditForAddEditUser ||
      nextProps.roleIdForAddEditUserProps !== prevState.roleIdForAddEditUser ||
      nextProps.storeTextForAddEditUserProps !==
        prevState.storeTextForAddEditUser ||
      nextProps.disableForAddEditUserProps !==
        prevState.disableForAddEditUser ||
//Task #3461 //Maksud
        nextProps.ContactLookupEnabledForAddEditUserProps !==
        prevState.ContactLookupEnabledForAddEditUser ||

      nextProps.userEditObj !== prevState.editUserObj ||
      nextProps.defaultCountryCodeForAddEditUserProps !==
        prevState.defaultCountryCode ||
      nextProps.registerationDateForAddEditUserProps !==
        prevState.registerationDateForAddEditUser ||
      nextProps.usedCreditForAddEditUserProps !==
        prevState.usedCreditForAddEditUser ||
      nextProps.userEditProps !== prevState.userEditText ||
      nextProps.unAssignPhoneNumberList !== prevState.unAssignedNumbers ||
      nextProps.pendingUnAssignPhoneNumbers !==
        prevState.pendingUnassignPhoneNumber ||
      nextProps.searchTextBox_Text !== prevState.searchTextBox ||
      nextProps.whiteLabel !== prevState.whiteLabelText ||
      nextProps.activeId !== prevState.rowActiveId ||
      nextProps.emailEnabledForUser !== prevState.emailEnabled
    ) {
      return {
        searchTextBox: nextProps.searchTextBox_Text,
        userEditText: nextProps.userEditProps,
        editUserObj: nextProps.userEditObj,
        disableForAddEditUser: nextProps.disableForAddEditUserProps,
        //Task #3461 //Maksud
        ContactLookupEnabledForAddEditUser:nextProps.ContactLookupEnabledForAddEditUserProps,
        roleIdForAddEditUser: nextProps.roleIdForAddEditUserProps,
        storeTextForAddEditUser: nextProps.storeTextForAddEditUserProps,
        creditForAddEditUser: nextProps.creditLimitForAddEditUserProps,
        uniqueNumberForAddEditUser: nextProps.uniqueNumberCostAddEditUserProps,
        subscriptionNameForAddEditUser:
          nextProps.subscriptionNameAddEditUserProps,
        subscriptionForAddEditUser: nextProps.subscriptionAddEditUserProps,
        nameForAddEditUser: nextProps.nameAddEditUserProps,
        emailForAddEditUser: nextProps.emailAddEditUserProps,
        uniqueNumber: nextProps.uniqueNumberProp,
        userList: nextProps.filteringUserProp,
        subscriptionList: nextProps.subscriptionList,
        errorOccured: nextProps.errorOccured,
        defaultCountryCode: nextProps.defaultCountryCodeForAddEditUserProps,
        registerationDateForAddEditUser:
          nextProps.registerationDateForAddEditUserProps,
        usedCreditForAddEditUser: nextProps.usedCreditForAddEditUserProps,
        unAssignedNumbers: nextProps.unAssignPhoneNumberList,
        pendingUnassignPhoneNumber: nextProps.pendingUnAssignPhoneNumbers,
        whiteLabelText: nextProps.whiteLabel,
        rowActiveId: nextProps.activeId,
        emailEnabled: nextProps.emailEnabledForUser,
      };
    } else return null;
  }
  filterBySelectedStatus = (filterByStatus: any) => {
    let filteredList: any;
    if (filterByStatus === "disabled") {
      this.setState({
        selectedSearch: "disabled",
      });
      filteredList = this.props.userList.filter(
        (x: any) => x.Disabled === true
      );
      this.props.getUserListPropsFilter(filteredList);
    } else if (filterByStatus === "enabled") {
      this.setState({
        selectedSearch: "enabled",
      });
      filteredList = this.props.userList.filter(
        (x: any) => x.Disabled === false
      );
      this.props.getUserListPropsFilter(filteredList);
    } else {
      this.setState({
        selectedSearch: "status_all",
      });
      this.props.getUserListPropsFilter(this.props.userList);
    }
  };
  emptyObject = (callingFrom: any) => {
    this.props.setEmail("");
    this.props.setName("");
    this.props.setSubscriptionId("");
    this.props.setSubscritpionName("");
    this.props.setCreditLimit("");
    this.props.setUniqueNumberCost("");
    this.props.setRoleId(20);
    this.props.setStoreText(true);
    this.props.setRegisterationDate("");
    this.props.setDefaultCountryCode(1);
    this.props.setSubscritpionName("");
    this.props.uniqueNumberProps("");
    this.props.setDisable(false);
    this.props.setContactLookupEnabled(false);
    this.props.getWhiteLabel("");
    this.props.setEmailEnabled(false);
    if (callingFrom === "add") {
      this.props.showHideListProp(false);
    }
  };
  setValueInObject = (object: any) => {
    var data = this.state.subscriptionList
      .filter((opt: any) => opt.subscription.Id === object.SubscriptionId)
      .map((ele: any) => ele.subscription.Name)[0];
    this.props.setEmail(object.Email);
    this.props.setName(object.UserName);
    this.props.setSubscriptionId(object.SubscriptionId);
    this.props.setCreditLimit(object.CreditLimit);
    this.props.setDisable(object.Disabled);
    this.props.setContactLookupEnabled(object.ContactLookupEnabled);
    this.props.setUniqueNumberCost(object.UniqueNumberCost);
    this.props.setRoleId(object.Role);
    this.props.setStoreText(object.StoreText);
    this.props.setRegisterationDate(object.RegistrationDate);
    this.props.setDefaultCountryCode(object.DefaultCountryCode);
    this.props.setSubscritpionName(data);
    this.props.uniqueNumberProps(object.UniqueNumber);
    this.props.setUsedCredits(object.UsedCredit);
    this.props.getWhiteLabel(object.WhiteLabelBrand);
    this.props.setEmailEnabled(object.EmailEnabled);
  };
  getWhiteLabel = (subscriptionId: any) => {
    var getSubscriptionDetails = this.props.subscriptionList.filter(
      (obj: any) => obj.Id === subscriptionId
    );
    this.props.getWhiteLabelCompanyListProp(
      subscriptionId,
      getSubscriptionDetails
    );
  };
  openEditForm = (object: any) => {

    this.setState({
      type: "edit",
      calledFrom: "edit",
      editDetail: object,
    });   
    
    //#3804 Maksud
    var comId = "";
    if (this.props.companyId && this.props.companyId) {
      comId = this.props.companyId;        
    }
    this.props.getSubscriptionBycompId(comId);

    this.setValueInObject(object);
    this.props.getUnAssignPhoneNumber(
      null,
      object.SubscriptionId,
      this.props.companyId
    );
    this.props.showHideListProp(false);
    this.props.hideUserTabFromCompanyDetail(true);
  };
  addUserFormValue = (value: any) => {

    //#3804 Maksud
    var comId = "";
    if (this.props.companyId && this.props.companyId) {
      comId = this.props.companyId;        
    }
    this.props.getSubscriptionBycompId(comId);
    
    this.props.showHideListProp(false);
    this.emptyObject("add");
    this.setState({
      type: "add",
    });
    this.props.hideUserTabFromCompanyDetail(true);
  };
  cancelButton = () => {
    this.props.showHideListProp(true);
    this.props.uniqueNumberProps("");
    this.props.addUserCalledFromCompanyTab(false);
  };
  public render() {
    return (
      <React.Fragment>
        <div className="layout-content">
          {this.props.companyId !== undefined ? null : (
            <NotificationComponent />
          )}
          {this.props.showHideList === true ? (
            <div className="container-fluid">
              {this.props.userListPending === false ? (
                this.state.errorOccured &&
                this.state.errorOccured.split(":")[1] === "Users" ? (
                  <div className="container-fluid flex-grow-1 container-p-y">
                    {this.state.errorOccured.split(":")[0]}
                  </div>
                ) : (
                  <UserListComponent
                    companyId={this.props.companyId}
                    filterationByTxt={(newList: any) => {
                      this.props.getUserListPropsFilter(newList);
                    }}
                    actualUserList={this.props.userList}
                    selectedSearchFilter={this.state.selectedSearch}
                    ColumnName={[
                      "",
                      "ID",
                      "Name",
                      "Email",
                      "Role",
                      "Number",
                      "Country Code",
                      "Store Text",
                      "Used Credit",
                      "Disabled",
                    ]}
                    listCalledFrom={"UserList"}
                    userList={this.state.userList}
                    openEditComponent={(object: any) =>
                      this.openEditForm(object)
                    }
                    showHideListUser={(value: any) =>
                      this.addUserFormValue(value)
                    }
                    searchFunction={(value: any) => {
                      this.filterBySelectedStatus(value);
                    }}
                    addUserCalledFromCompanyTab={(value: any) => {
                      this.props.emptyUnAssignedNumbers([]);
                      this.props.addUserCalledFromCompanyTab(value);
                    }}
                    searchTextBox_Props={this.state.searchTextBox}
                    searchTextBox_Text={(value: any) =>
                      this.props.setSearchBoxValue(value)
                    }
                    activeIdProp={this.state.rowActiveId}
                    rowActiveIdCompanyProps={(id: any) =>
                      this.props.rowActiveId(id)
                    }
                  />
                )
              ) : (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          ) : (
            <UserEditComponent
              loggedInUserRole={this.props.loggedinUserAccessLevel}
              userEditObj={this.props.userEditObj}
              emailForAddEditUserProp={this.state.emailForAddEditUser}
              nameForAddEditUserProp={this.state.nameForAddEditUser}
              uniquePhoneNumber={this.state.uniqueNumber}
              subscriptionNameForAddEditUserProp={
                this.state.subscriptionNameForAddEditUser
              }
              subscriptionForAddEditUserProp={
                this.state.subscriptionForAddEditUser
              }
              uniqueNumberCostForAddEditUserProp={
                this.state.uniqueNumberForAddEditUser
              }
              creditLimitForAddEditUserProp={this.state.creditForAddEditUser}
              roleIdForAddEditUserProp={this.state.roleIdForAddEditUser}
              storeTextForAddEditUserProp={this.state.storeTextForAddEditUser}
              disableForAddEditUserProp={this.state.disableForAddEditUser}
              emailEnabledUserProp={this.state.emailEnabled}
              companyId={this.props.companyId}
              defaultCountryCodeForAddEditUserPRops={
                this.state.defaultCountryCode
              }
              usedCreditForAddEditUserProps={
                this.state.usedCreditForAddEditUser
              }
              calledFrom={this.state.type}
              editData={this.state.editDetail}
              registerationDate={this.state.registerationDateForAddEditUser}
              subscriptionList={this.state.subscriptionList}
              whiteLabelPrps={this.state.whiteLabelText}
              deleteUserProps={(email: string,confirm:boolean) => {
                this.props.deleteAllUserDataByEmailProps(
                  email.toString(),
                  this.props.companyId !== undefined
                    ? this.props.companyId
                    : null,
                    confirm
                );
              }}
              addUpdateUserProp={(type: string, data: {}) => {
                this.props.showHideListProp(false);
                this.props.addUpdateUserProps(
                  type,
                  data,
                  this.props.companyId !== undefined
                    ? this.props.companyId
                    : null
                );
              }}
              changeState={() => this.cancelButton()}
              showHideListNumbers={(value: any) => {
                this.props.showHideListNumbers(value);
              }}
              emailSetForAddEdit={(value: any) => {
                this.props.setEmail(value);
              }}
              nameSetForAddEdit={(value: any) => {
                this.props.setName(value);
              }}
              subscriptionIdSetForAddEdit={(value: any) => {
                this.props.setSubscriptionId(value);
              }}
              subscriptionNameForAddEdit={(value: any) => {
                this.props.setSubscritpionName(value);
              }}
              uniqueNumberCostForAddEdit={(value: any) => {
                this.props.setUniqueNumberCost(value);
              }}
              creditLimitForAddEdit={(value: any) => {
                this.props.setCreditLimit(value);
              }}
              roleForAddEdit={(value: any) => {
                this.props.setRoleId(value);
              }}
              storeTextForAddEdit={(value: any) => {
                this.props.setStoreText(value);
              }}
              EmailEnabledForAddEdit={(value: any) => {
                this.props.setEmailEnabled(value);
              }}
              disableForAddEdit={(value: any) => {
                this.props.setDisable(value);
              }}
              ContactLookupEnabledForAddEditUser={(value: any) => {
                this.props.setContactLookupEnabled(value);
              }}              
              ContactLookupEnabledForAddEditUserProp={this.state.ContactLookupEnabledForAddEditUser}
              defaultCountryCodeMethod={(value: any) => {
                this.props.setDefaultCountryCode(value);
              }}
              deleteNumberFromDatabase={(value: any) => {
                var data = [
                  {
                    PhoneNumber: value,
                  },
                ];
                this.props.releaseNumberFromDatatabse(data, "Users");
              }}
              uniqueNumberProps={(value: any) => {
                this.props.uniqueNumberProps(value);
              }}
              pendingUnAssignPhoneNumberProps={
                this.state.pendingUnassignPhoneNumber
              }
              listUnAssignPhoneNumberProps={this.state.unAssignedNumbers}
              getUnAssignedNumbers={(
                smsServieType: any,
                subscriptionId: any
              ) => {
                this.props.getUnAssignPhoneNumber(
                  smsServieType,
                  subscriptionId,
                  this.props.companyId
                );
              }}
              getWhiteLabel={(subscriptionId: any) => {
                this.getWhiteLabel(subscriptionId);
              }}
              whiteLabelText={(text: any) => {
                this.props.getWhiteLabelText(text);
              }}
              removeNumberFromYakchat={(
                data: any,
                searchBy: any,
                callingFrom: string
              ) => {
                this.props.removeNumberFromYakchat(data, searchBy, callingFrom);
              }}
              addUpdatePhoneNumbers={(numberObj: any) =>
                this.props.fetchAddUpdatePhoneNumbers(numberObj)
              }
              updateUserEmailNotifications={(obj: any) =>
                this.props.updateUserEmailNotifications(obj)
              }
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    uniqueNumberProp: props.characterReducer.showUniqueNumberReducer,
    showHideList: props.characterReducer.showHideUser,
    userList: props.characterReducer.userList,
    errorOccured: props.characterReducer.errorListMessage,
    savedSuccessfull: props.characterReducer.dataSaved,
    userListPending: props.characterReducer.pendingUserList,
    filteringUserProp: props.characterReducer.filteringUser,
    subscriptionList: props.characterReducer.subscriptionList,
    emailAddEditUserProps: props.characterReducer.emailForAddEditUser,
    nameAddEditUserProps: props.characterReducer.nameForAddEditUser,
    subscriptionAddEditUserProps: props.characterReducer.subscriptionID,
    subscriptionNameAddEditUserProps:
      props.characterReducer.subscriptionNameForAddEditUser,
    uniqueNumberCostAddEditUserProps:
      props.characterReducer.uniqueNumbetCostForAddEditUser,
    creditLimitForAddEditUserProps:
      props.characterReducer.creditLimitForAddEditUser,
    roleIdForAddEditUserProps: props.characterReducer.roleIdForAddEditUser,
    storeTextForAddEditUserProps:
      props.characterReducer.storeTextForAddEditUser,
    disableForAddEditUserProps: props.characterReducer.disabledForAddEditUser,
//Task #3461 //Maksud
 //Task #3461 //Maksud
    ContactLookupEnabledForAddEditUserProps:props.characterReducer.ContactLookupEnabledForAddEditUser,      
    userEditObj: props.characterReducer.objUserData,
    defaultCountryCodeForAddEditUserProps:
      props.characterReducer.defaultCountryCode,
    registerationDateForAddEditUserProps:
      props.characterReducer.registerationDate,
    usedCreditForAddEditUserProps: props.characterReducer.usedCredit,
    userEditProps: props.characterReducer.userEditTabReducer,
    loggedinUserAccessLevel: props.characterReducer.loggedinUserAccessLevel,
    pendingUnAssignPhoneNumbers:
      props.characterReducer.pendingUnAssignPhoneNumbersReducer,
    unAssignPhoneNumberList: props.characterReducer.unAssignPhoneNumberReducer,
    userAddCalledFromCompanyTab:
      props.characterReducer.userAddCalledFromCompanyTabReducer,
    searchTextBox_Text: props.characterReducer.searchTextBoxValue_UserReducer,
    whiteLabel: props.characterReducer.whiteLabelReducer,
    activeId: props.characterReducer.rowActiveIdReducer,
    emailEnabledForUser: props.characterReducer.emailEnabledReducer,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    setSearchBoxValue: (value: any) => {
      dispatch<any>(action.searchBox_Value_In_Users(value));
    },
    rowActiveId: (id: any) => {
      dispatch(action.rowActiveId(id));
    },
    showHideListNumbers: (value: any) => {
      dispatch(action.showHideNumberList(value));
    },
    getUserListprops: (subscriptionId: any, companyId: null) => {
      dispatch(action.pendingUserList(true));
      dispatch(action.getUserList(subscriptionId, companyId));
    },
    showHideListProp: (value: boolean) => {
      dispatch(action.showHideListUser(value));
    },
    getUserListPropsFilter: (list: any) => {
      dispatch<any>(action.getUserListAction(list));
    },
    getSubscriptionBycompId: (compId: any) => {

      dispatch<any>(action.getSubscriptionList(compId, "Users"));
    },
    getWhiteLabelText: (text: any) => {
      //debugger
      dispatch(action.getWhiteLabelText(text));
    },
    addUpdateUserProps: (type: string, data: {}, compId: any) => {
      dispatch(action.addUpdateUsers(type, data, compId, "addUser"));
    },
    deleteAllUserDataByEmailProps: (email: string, compId: any,confirm:boolean) => {
      dispatch(action.deleteAllUserDataByEmail(email, compId, "userDelete",confirm));
    },
    uniqueNumberProps: (value: any) => {
      dispatch(action.showNumberInUniqueNumber(value));
    },
    editUserObject: (obj: any) => {
      dispatch(action.editUserObject(obj));
    },
    setEmail: (value: any) => {
      dispatch(action.emailForUser(value));
    },
    setEmailEnabled: (value: any) => {
      dispatch(action.EmailEnabledForUser(value));
    },
    setName: (value: any) => {
      dispatch(action.nameForUser(value));
    },
    setSubscriptionId: (value: any) => {
      dispatch(action.subscriptionIdForUser(value));
    },
    setSubscritpionName: (value: any) => {
      dispatch(action.subscriptionNameForUser(value));
    },
    setUniqueNumberCost: (value: any) => {
      dispatch(action.uniqueNumberCostForUser(value));
    },
    setCreditLimit: (value: any) => {
      dispatch(action.creditLimitForUser(value));
    },
    setRoleId: (value: any) => {
      dispatch(action.roleIdForUser(value));
    },
    setStoreText: (value: any) => {
      dispatch(action.storeTextForUser(value));
    },
    setDisable: (value: any) => {
      dispatch(action.disabledForUser(value));
    },
  //Task #3461 //Maksud
    setContactLookupEnabled: (value: any) => {
      //debugger;
      dispatch(action.ContactLookupEnabledForUser(value));
    },
    setDefaultCountryCode: (value: any) => {
      dispatch(action.defaultCountryCodeForUser(value));
    },
    setRegisterationDate: (value: any) => {
      dispatch(action.registerationDateForUser(value));
    },
    setUsedCredits: (value: any) => {
      dispatch(action.usedCreditForUser(value));
    },
    releaseNumberFromDatatabse: (value: any, callingFrom: any) => {
      dispatch(action.getNumberInfoFromTwillio(value, "All", callingFrom));
    },
    hideUserTabFromCompanyDetail: (value: any) => {
      dispatch(action.hideUserTabFromCompanyDetail(value));
    },
    getUnAssignPhoneNumber: (
      smsServiceType: any,
      subscriptionId: any,
      companyId: any
    ) => {
      dispatch<any>(
        action.unAssignedPhoneNumbers(smsServiceType, subscriptionId, companyId)
      );
    },
    addUserCalledFromCompanyTab: (value: any) => {
      dispatch<any>(action.userAddCalledFromCompanyTab(value));
    },
    getWhiteLabelCompanyListProp: (
      subscriptionId: any,
      subscriptionObject: any
    ) => {
      dispatch(action.getWhiteLabel(subscriptionId, subscriptionObject));
    },
    getWhiteLabel: (whiteLabelText: any) => {
      ////debugger;
      dispatch(action.getWhiteLabelText(whiteLabelText));
    },
    emptyUnAssignedNumbers: (data: any) => {
      dispatch<any>(action.unassignPhoneNumberList(data));
    },
    removeNumberFromYakchat: (
      data: any,
      searchBy: any,
      callingFrom: string
    ) => {
      dispatch<any>(
        action.deleteNumberByNumberModel(data, searchBy, callingFrom)
      );
    },
    fetchAddUpdatePhoneNumbers: (numberObj: any) => {
      dispatch(action.fetchAddUpdatePhoneNumbers(numberObj));
    },
    updateUserEmailNotifications: (emailObj: any) => {
      dispatch(action.updateUserEmailNotifications(emailObj));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent);
