import { adalApiFetch, apiUrl, adalApiFetchDelete } from "../adalConfig";

export const getcompanyList = () => {
  const url = `${apiUrl}/getCompanies`;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};
export async function getloggedInUserDetail () {
  const url = `${apiUrl}/GetUserDetails`;
  const response= await adalApiFetch(url, {
    accept: "application/json;",
  });
  return response;
};
export async function GeneratePartnerBillingSnapshot(companyId: number) {
  const url=`${apiUrl}/Reporting/GeneratePartnerBillingSnapshot?companyId=`+ companyId;
  //debugger;
  const response= await adalApiFetch(url, {
          accept: "application/octet-stream;",
        });
     console.log(response);
     return response;

};
export async function GeneratePartnerMessageSnapshotForUser(userEmail: string) {
  const url=`${apiUrl}/GeneratePartnerMessageSnapshotForUser?userEmail=`+userEmail;
  //debugger;
  const response= await adalApiFetch(url, {
          accept: "application/octet-stream;",
        });
     console.log(response);
     return response;

};
export const getParentCompanyList = () => {
  const url = `${apiUrl}/GetAllParentCompanies`;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};
export const addUpdateCompany = (type: string, data: any) => {
  if (type === "add") {
    const url = `${apiUrl}/addCompany`;
    return adalApiFetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  } else {
    const url = `${apiUrl}/modifyCompany?companyId=` + data.id;
    return adalApiFetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};
export const removeCompany = (parameter: string) => {
  const url = `${apiUrl}/removeCompany?companyId=` + parameter;
  return adalApiFetchDelete(url, {
    method: "DELETE",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};
