
import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getLoggedInUSerName } from '../../adalConfig';
import * as commonMethod from "../../commonMethods";


class ConfirmationUserGroupDeleteComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props)
    this.state = {
      ConfirmaDeleteEmail: "",
      InvalidConfirmDeleteEmail: "",
      ErrorEmail: "",
    }
  }
  deleteAllUserData() {
    if (this.state.ConfirmaDeleteEmail !== '') {
      this.props.deleteSucces(true);
    }
    else {

      this.setState({
        InvalidConfirmDeleteEmail:
          "Please enter valid user id ( email )",
      });
    }
  }

  componentDidMount() {
    var userData = getLoggedInUSerName();
    if (userData !== null) {
      if (userData.profile !== null) {
        this.setState({
          loggedInUserName: userData.userName
        })
      }
    }
  }
  renderHtml() {
    return (
      <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-user-delete">
              <i className='fas fa-exclamation-triangle delete_alert_icon' ></i>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>The user’s text messages will be deleted when a User or Group is deleted. Once deleted, the text messages cannot be recovered.  </p>
              <p>Use the ‘disable’ checkbox to remove a user license without deleting the associated text messages. If you are sure you want to delete the User/Group and associated text messages then enter your User ID (your Email address for tracking) into the box below and click the Delete button.</p>
              {
                <div className='user-delete'>

                  <input
                    type="text"
                    className="form-control"
                    name="validation-email"
                    placeholder="User ID (Email)"
                    disabled={
                      this.props.calledFrom === "edit" ? true : false
                    }
                    onChange={(e) => {
                      var valid = commonMethod.validEmail(
                        e.target.value
                      );
                      if (!valid) {
                        this.setState({
                          InvalidConfirmDeleteEmail:
                            "Please Enter Valid Email Address",
                        });
                      }
                      else {
                        this.setState({ InvalidConfirmDeleteEmail: "" });
                      }
                      if (this.state.loggedInUserName === e.target.value) {
                        this.setState({
                          ConfirmaDeleteEmail: e.target.value,
                          ErrorEmail: "",
                        });
                      }
                      else {
                        this.setState({
                          InvalidConfirmDeleteEmail:
                            "Please enter valid user id ( email )",
                        });
                      }

                    }
                    }
                    value={this.state.ConfirmDeleteEmail}
                  />
                  <p className='validationClass'>
                    <small className="form-text">
                      {this.state.ErrorEmail}{" "}
                    </small>
                    <small className="form-text">
                      {this.state.InvalidConfirmDeleteEmail}{" "}
                    </small>
                  </p>
                </div>
              }
            </div>
            <div className="modal-footer model-footer-user-delete">
              <button type="button" className="btn btn-default cancelBtn" data-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary delete_btn_dialog_footer" data-dismiss={this.state.ConfirmaDeleteEmail !== '' ? "modal" : null} onClick={() => { this.deleteAllUserData() }}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderDeleteConfirmation() {
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Are you sure you want to Delete this number ?
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default cancelBtn" data-dismiss="modal">Cancel</button>
            <button type="button" className="btn btn-primary " data-dismiss="modal" onClick={() => { this.props.deleteSucces() }}>Delete</button>
          </div>
        </div>
      </div>
    );
  }
  public render() {
    return (
      <React.Fragment>
        {this.props.confimationMessage !== '' && this.props.confimationMessage !== undefined ?
          this.renderDeleteConfirmation()
          :
          this.renderHtml()
        }

      </React.Fragment>
    );
  }
};


const mapStateToProps = (props: any) => {
  return {
    errorOccured: props.characterReducer.errorOccured,
    savedSuccessfull: props.characterReducer.dataSaved,
    deletedMessage: props.characterReducer.deletedMessgae
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {

  }
}
export default connect(mapStateToProps,
  mapDispatchToProps)(ConfirmationUserGroupDeleteComponent);