import * as React from 'react';
import { DataTableComponent }from '../sharedComponents/dataTableComponent';

export class UserListComponent extends React.Component<any, any> {
    constructor(props: any , state : any) {
        super(props)
        this.state = {
            userData: [],
            searchType:'',
            searchBox:"",
            activeId:""

        }


    }
    static getDerivedStateFromProps(nextProps: any, prevState: any) {

      if (nextProps.userList !== prevState.userData ||
        nextProps.searchTextBox_Props !== prevState.searchBox ||
        nextProps.activeIdProp !== prevState.activeId) {
          return { 
            userData: nextProps.userList,     
            searchType:nextProps.selectedSearchFilter,
            searchBox:nextProps.searchTextBox_Props,
            activeId:nextProps.activeIdProp

          };
      }
      else return null;
  }
    public render() {
      
        return (
            <React.Fragment>
             <div className={this.props.companyId=== undefined?"positionRelative paddTop46":"tabTableHeight positionRelative paddTop46"}>
                      <div className={"card-datatable table-responsive "}>
                        <DataTableComponent
                          listCalled = {this.props.listCalledFrom}
                          columnName={this.props.ColumnName}
                          rowsList={this.state.userData}
                          calledFrom={'Users'}
                          openEditComponent={(object: {}) => {
                           this.props.openEditComponent(object)
                            }}
                          filteredByTxtBoxSearch ={(list:any)=>{this.props.filterationByTxt(list)}}
                          actualData={this.props.actualUserList}
                          searchBy={this.props.selectedSearchFilter}
                          showHideListProp={(value:any)=>{
                            this.props.showHideListUser(value);
                            if(this.props.companyId !== undefined){
                              this.props.addUserCalledFromCompanyTab(true)
                            }
                            else{
                             this.props.addUserCalledFromCompanyTab(false)
                           }
                          }}
                          searchFunctionality={(value:any,type:any)=>{
                          this.props.searchFunction(value)
                          }}
                          searchBoxText={this.state.searchBox}
                          setSearchBoxText={(value:any)=>{this.props.searchTextBox_Text(value)}}
                          rowActiveIdCompany_Props ={(id:any)=>{this.props.rowActiveIdCompanyProps(id)}}
                          activeCompanyId={this.state.activeId}
                        />

                      </div>
                    </div>
            </React.Fragment>
        );
    }
};

