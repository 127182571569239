import { Dispatch } from "redux";
import * as index from "./index";
import * as userService from "../../services/usersComponent.service";
import * as companyService from "../../services/companyComponent.service";
import * as subscriptionService from "../../services/subscriptionComponent.service";
import * as groupService from "../../services/groupsComponent.Service";
import * as commonMethod from "../../commonMethods";
import * as numberService from "../../services/numbersComponent.service";
import history from "../../history";
import { authContext } from "../../adalConfig";
//import { type } from "os";

export type CharacterActions = index.ICharacterGetAllAction;
export type UserListActions = index.IUserListGetAllUser;

export const getUserList = (subscriptionId: any, companyId: any) => {
  return async (dispatch: Dispatch) => {
    try {
      userService
        .userList(subscriptionId, companyId)
        .then((result) => {
          var data = result && JSON.parse(result);
          var filter_Ref_Objects = data.filter((obj: any) => obj.$ref);
          var filter_UserObject = data.filter((obj: any) => !obj.$ref);
          //console.log(filter_Ref_Objects, filter_UserObject, 'seee')
          var dataList_containsSubscription = filter_UserObject.filter(
            (x: any) => x.Subscription
          );
          if (!data.Message) {
            if (filter_Ref_Objects.length !== 0) {
              var filterRecords = data.map((element: any) => {
                var temp = Object.assign({}, element);
                if (temp.$ref) {
                  var refrenceData = filterRefDataFromJSONObject_Users(
                    dataList_containsSubscription,
                    temp.$ref
                  );
                  refrenceData.backGroundColor =
                    commonMethod.randomColorGenerator();
                  return refrenceData;
                } else {
                  temp.backGroundColor = commonMethod.randomColorGenerator();
                  return temp;
                }
              });
              data = filterRecords;
            } else {
              var list = data.map((element: any) => {
                element.backGroundColor = commonMethod.randomColorGenerator();
                return element;
              });
              data = list;
            }

            dispatch({
              usersList: data,
              type: index.CharacterActionTypes.GET_ALL_USERSLIST,
            });
            var filteredList =
              data.length !== 0
                ? data.filter((x: any) => x.Disabled === false)
                : data;
            dispatch<any>(getUserListAction(filteredList));
            dispatch<any>(pendingUserList(false));
          } else {
            dispatch<any>(pendingUserList(false));
            dispatch<any>(displayErrorListMessage(data.Message + ":Users"));
          }
        })
        .catch((err) => {
          setTimeout(() => {
            dispatch<any>(pendingUserList(false));
          }, 500);
        });
    } catch (error) {
      setTimeout(() => {
        dispatch<any>(pendingUserList(false));
      }, 500);
    }
  };
};
export const searchBox_Value_InTables = (searchText: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SEARCH_TEXT_BOX_VALUE,
      searchTextBoxValue: searchText,
    });
  };
};
export const searchBox_Value_In_Users = (searchText: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SEARCH_TEXT_BOX_VALUE_USER,
      searchTextBoxValue_User: searchText,
    });
  };
};
export const searchBox_Value_In_Groups = (searchText: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SEARCH_TEXT_BOX_VALUE_GROUP,
      searchTextBoxValue_Group: searchText,
    });
  };
};
export const searchBox_Value_In_Subscription = (searchText: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SEARCH_TEXT_BOX_VALUE_SUBSCRIPTION,
      searchTextBoxValue_Subscription: searchText,
    });
  };
};
export const pendingNumberList = (pendingResult: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.PENDING_NUMBER_LIST,
      pendingNumbeList: pendingResult,
    });
  };
};
export const showHideNumberList = (showHide: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SHOW_HIDE_LIST_NUMBERS,
      showHideNumberListAction: showHide,
    });
  };
};
export const filterPhoneNumberList = (list: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      filterNumberList: list,
      type: index.CharacterActionTypes.FILTER_NUMBER_LIST,
    });
  };
};
export const getPhoneNumerList = (smsServiceType: any, searchBy: any) => {
  return async (dispatch: Dispatch) => {
    try {
      numberService
        .getPhoneNumbersApi(smsServiceType)
        .then((result) => {
          var data = JSON.parse(result);
          dispatch<any>(getCompanyList("Numbers", data, searchBy));
        })
        .catch((err: any) => {
          dispatch<unknown | any>(pendingNumberList(false));
        });
    } catch (error) {
      dispatch<any>(pendingNumberList(false));
    }
  };
};

/*API calls by Niyi*/

export const allServiceProvidersAction = (providers: any[]) => ({
  type: index.CharacterActionTypes.GET_ALL_SERVICE_PROVIDERS,
  payload: providers,
});

export const fetchAllServiceProviders = () => {
  return async (dispatch: Dispatch) => {
    try {
      const resp = await numberService.allServiceProvidersCall();
      const json = JSON.parse(resp);
      const data = json.filter((provider: any) => provider.IsActive);
      dispatch<any>(allServiceProvidersAction(data));
    } catch (error) {
      dispatch<unknown | any>(errorOccurred(""));
    }
  };
};

export const allServiceProvidersAccountAction = (accounts: any[]) => ({
  type: index.CharacterActionTypes.GET_ALL_SERVICE_PROVIDERS_ACCOUNT,
  payload: accounts,
});

export const fetchAllServiceProvidersAccount = (
  prodiverId: number | string
) => {
  return async (dispatch: Dispatch) => {
    try {
      const resp = await numberService.serviceProviderAccountsByProviderCall(
        prodiverId
      );
      const data = JSON.parse(resp);
      dispatch<any>(allServiceProvidersAccountAction(data));
    } catch (error) {
      dispatch<any>(errorOccurred(""));
    }
  };
};

export const fetchAddUpdatePhoneNumbers = (numberObj: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const resp = await numberService.addUpdatePhoneNumbersCall(numberObj);
      const data = await JSON.parse(resp);
      if (data.Message) {
        dispatch<any>(errorOccurred(resp.Message));
        dispatch<any>(showHideNumberList(false));
      } else {
        dispatch<any>(savingNumberToDataBase(false));
        setTimeout(() => {
          dispatch<any>(savingNumberToDataBase(""));
        }, 3000);
        dispatch<any>(changeButtonText("Update Changes"));
      }
    } catch (error) {
      dispatch<any>(errorOccurred("Cannot complete request"));
    }
  };
};

export const updateSubscriptionEmailNotifications = (emailObject: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await subscriptionService.UpdateSubscriptionEmailNotifications(
        emailObject
      );
      dispatch<any>(savedData(true));
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred("Email Notification Error"));
      }, 3000);
    }
  };
};

export const updateUserEmailNotifications = (emailObject: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await userService.UpdateUserEmailNotifications(emailObject);
      dispatch<any>(savedData(true));
    } catch (error) {
      const err = error as string;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred("Email Notification Error"));
      }, 3000);
    }
  };
};

export const ModifyFreeCreditRenewalDateAction = (dateString: string) => ({
  type: index.CharacterActionTypes.MODIFY_FREE_CREDIT_DATE,
  payload: dateString,
});

export const fetchModifyFreeCreditRenewalDate = (
  subscriptionId: number,
  date: Date
) => {
  return async (dispatch: Dispatch) => {
    try {
      const resp = await subscriptionService.ModifyFreeCreditRenewalDate(
        date,
        subscriptionId
      );
      const json = JSON.parse(resp);
      dispatch<any>(ModifyFreeCreditRenewalDateAction(json.data));
    } catch (error) {
      const err = error as string;
      dispatch<any>(errorOccurred(`${err}, Renewal data not available`));
    }
  };
};

/*API calls by Niyi */

export const bindCompanyList = (newList: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      companyList: newList,
      type: index.CharacterActionTypes.COMPANY_LIST,
    });
  };
};
export const getCompanyList_Filter = (list: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      filterCompanyList: list,
      type: index.CharacterActionTypes.FILTER_COMPANYLIST,
    });
  };
};
export const getWhiteLabelText = (whiteLabel: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.WHITE_LABEL_FOR_USER,
      whiteLabelAction: whiteLabel,
    });
  };
};
export const getWhiteLabelLoggedInUser = (whiteLabel: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.WHITE_BRAND_LOGGED_IN_USER,
      whiteLabelActionLoggedInUser: whiteLabel,
    });
  };
};
export const getWhiteLabel = (subscriptionId: any, subscriptionObject: any) => {
  var whiteLabel = "";
  ////debugger;
  return async (dispatch: Dispatch) => {
    try {
      companyService
        .getcompanyList()
        .then((result) => {
          var data = JSON.parse(result);
          if (!data.Message) {
            if (data.length !== 0) {
              var getCompany = data.filter(
                (obj: any) => obj.Id === subscriptionObject[0].CompanyId
              )[0];
              if (getCompany.IsParent === false && getCompany.ParentId !== 0) {
                var getParentCompanyDetail = data.filter(
                  (obj: any) => obj.Id === getCompany.ParentId
                )[0];
                whiteLabel = getParentCompanyDetail.WhiteLabelBrand;
              } else if (getCompany.IsParent === true) {
                whiteLabel = getCompany.WhiteLabelBrand;
              } else {
                whiteLabel = "";
              }
            }
            ////debugger;
            dispatch<any>(getWhiteLabelText(whiteLabel));
          } else {
            dispatch<any>(displayErrorListMessage(data.Message + ":Company"));
            dispatch<any>(pendingCompanyList(false));
          }
        })
        .catch((err) => {
          dispatch<any>(pendingCompanyList(false));
        });
    } catch (error) {
      dispatch<any>(pendingCompanyList(false));
    }
  };
};
export const getCompanyList = (
  calledFrom: any,
  numberList: any,
  searchByForNumber: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      companyService
        .getcompanyList()
        .then((result) => {
          var data = JSON.parse(result);
          if (!data.Message) {
            // var sortedList =
            //   data.length !== 0
            //     ? data.sort((a: any, b: any) =>
            //         a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0
            //       )
            //     : data;
            dispatch<any>(bindCompanyList(data));
            if (calledFrom === "Numbers") {
              dispatch<any>(
                bindNumberList(data, numberList, searchByForNumber)
              );
            }
            dispatch<any>(getCompanyList_Filter(data));
            dispatch<any>(pendingCompanyList(false));
          } else {
            dispatch<any>(displayErrorListMessage(data.Message + ":Company"));
            dispatch<any>(pendingCompanyList(false));
          }
        })
        .catch((err) => {
          dispatch<any>(pendingCompanyList(false));
        });
    } catch (error) {
      dispatch<any>(pendingCompanyList(false));
    }
  };
};
export const bindNumberList = (
  companyList: any,
  numberList: any,
  searchByForNumber: any
) => {
  return async (dispatch: Dispatch) => {
    var newListWithCompanyName = numberList.map((item: any) => {
      //var companyName = "";
      if (item.CompanyId !== 0) {
        var object = companyList.filter((x: any) => x.Id === item.CompanyId);
        if (object.length !== 0) {
          item.CompanyName = object[0].Name;
        } else {
          item.CompanyName = "";
        }
      } else {
        item.CompanyName = "";
      }
      return item;
    });
    dispatch({
      phoneNumberList: newListWithCompanyName,
      type: index.CharacterActionTypes.GET_PHONE_NUMBER_LIST,
    });
    dispatch<any>(pendingNumberList(false));
    var filterList =
      searchByForNumber === "All" || searchByForNumber === undefined
        ? newListWithCompanyName
        : newListWithCompanyName.filter((x: any) => {
            return x.Type === searchByForNumber;
          });
    dispatch<any>(filterPhoneNumberList(filterList));
  };
};
export const savedData = (savedData: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SUCCESS_SAVED,
      dataSaved: savedData,
    });
  };
};
export const warningAfterGroupAdd = (groupAddWarning: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.WARNING_AFTER_GROUP_ADD,
      groupAddWarning: groupAddWarning,
    });
  };
};
export const pendingSubscriptionList = (pendingSubscriptionList: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.PENDING_SUBSCRIPTION_LIST,
      pendingSubscriptionList: pendingSubscriptionList,
    });
  };
};
export const pendingUserList = (pendingUserList: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.PENDING_USER_LIST,
      pendingUserList: pendingUserList,
    });
  };
};
export const pendingCompanyList = (pendingCompanyList: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.PENDING_COMPANY_LIST,
      pendingCompanyList: pendingCompanyList,
    });
  };
};
export const deletedSuccessfullyMessage = (deleted: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.DELETED_SUCCESSFULLY_MESSAGE,
      deletedMessgae: deleted,
    });
  };
};
export const showHideList = (listShowComponent: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SHOW_HIDE_LIST,
      listShowComponent: listShowComponent,
    });
  };
};
export const showHideListUser = (listShowComponent: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SHOW_HIDE_LIST_USER,
      listShowComponentUser: listShowComponent,
    });
  };
};
export const showHideListCompany = (listShowComponent: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SHOW_HIDE_LIST_COMPANY,
      listShowComponentCompany: listShowComponent,
    });
  };
};
export const hideGroupMembeFromGroup = (hideGroupMembrFromGroup: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.HIDE_GROUP_MEMBERS_GROUP_TAB,
      hideGroupMembrFromGroupTabActions: hideGroupMembrFromGroup,
    });
  };
};
export const showHideListGroups = (listShowComponent: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SHOW_HIDE_LIST_GROUP,
      listShowComponentGroup: listShowComponent,
    });
  };
};
export const errorOccurred = (errorMessage: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.ERROR_SAVED,
      errorOccured: errorMessage,
    });
  };
};
export const addUpdateUsers = (
  type: string,
  data: any,
  compId: any,
  calledFrom: any
) => {
  return async (dispatch: Dispatch) => {
    try {

      userService
        .addUpdateUser(type, data)
        .then((response) => {
          let res = typeof response !== 'string' && JSON.parse(response);
          if (response.indexOf('error') > -1) {
            dispatch<any>(
              errorOccurred(
                response || "Enter required details and try again"
              )
            );
            dispatch<any>(showHideListUser(false));
          }
          if (res.Message) {
            dispatch<any>(
              errorOccurred(
                response.statusText || "Enter required details and try again"
              )
            );
            dispatch<any>(showHideListUser(false));
          } else {
            // console.log('user')
            // console.log(data)
            dispatch<any>(unAssignedPhoneNumbers(null, null, ""));
            dispatch<any>(showHideListUser(true));
            if (calledFrom === "addGroup") {
              if (type !== "edit") {
                dispatch<any>(displaySelectedGroupName(""));
                dispatch<any>(showHideListGroups(false));
                dispatch<any>(hideGroupNonMemberTab(false));
                dispatch<any>(warningAfterGroupAdd(true));
              }
              dispatch<any>(
                getGroupListByCompanyAndSubscriptionId(null, compId, type)
              );
            } else if (calledFrom === "myProfile") {
              window.history.back();
            } else {
              dispatch<any>(userAddCalledFromCompanyTab(false));
              dispatch<any>(getUserList(null, compId));
            }
            dispatch<any>(searchBox_Value_InTables(""));
            dispatch<any>(savedData(true));
          }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(savedData(false));
            dispatch<any>(warningAfterGroupAdd(false));
          }, 3000);
        })
        .catch((err) => {
          dispatch<any>(showHideListUser(false));
          dispatch<any>(errorOccurred(err));
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(savedData(false));
          }, 3000);
        });
    } catch (error) {
      dispatch<any>(showHideListUser(false));
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const displaySelectedCompanyName = (selectedCompanyName: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SELECTED_COMPANY_NAME,
      selectedCompanyNameAction: selectedCompanyName,
    });
  };
};
export const displayActiveTabName = (activeTabName: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.ACTIVE_TAB_NAME,
      activeTabNameAction: activeTabName,
    });
  };
};
export const displaySelectedGroupName = (selectedGroupName: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SELECTED_GROUP_NAME,
      selectedGroupNameAction: selectedGroupName,
    });
  };
};
export const addUpdateCompany = (
  type: string,
  data: {},
  calledFrom: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      companyService
        .addUpdateCompany(type, data)
        .then((response) => {
          var data = JSON.parse(response);
          if (data.Message) {
            dispatch<any>(errorOccurred(data.Message));
            dispatch<any>(showHideListCompany(false));
          } else {
            dispatch<any>(savedData(true));
            if (type === "add") {
              dispatch<any>(displaySelectedCompanyName(""));
              dispatch<any>(showHideListCompany(true));
            } else if (calledFrom === "myCompanyProfile") {
              window.history.back();
            }
            dispatch<any>(getCompanyList("", [], ""));
          }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(savedData(false));
          }, 3000);
        })
        .catch((err) => {
          dispatch<any>(errorOccurred(err));
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
          }, 3000);
        });
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const getUserListAction = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      filteringUserAction: data,
      type: index.CharacterActionTypes.USER_FILTER_LIST,
    });
  };
};
export const rowActiveIdCompany = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      rowActiveIdCompany: id,
      type: index.CharacterActionTypes.ROW_ACTIVE_ID_COMPANY,
    });
  };
};
export const rowActiveId = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      rowActiveId: id,
      type: index.CharacterActionTypes.ROW_ACTIVE_ID,
    });
  };
};
export const rowActiveId_Subscription = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      rowActiveId_Sbscription: id,
      type: index.CharacterActionTypes.ROW_ACTIVE_ID_SUBSCRIPTION,
    });
  };
};
export const rowActiveId_Groups = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      rowActiveId_Groups: id,
      type: index.CharacterActionTypes.ROW_ACTIVE_ID_GROUPS,
    });
  };
};
export const rowActiveId_Numbers = (id: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      rowActiveId_Numbers: id,
      type: index.CharacterActionTypes.ROW_ACTIVE_ID_NUMBERS,
    });
  };
};

export const getSubscriptionListAction = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      filteringSubscriptionAction: data,
      type: index.CharacterActionTypes.SUBSCRIPTION_FILTER_LIST,
    });
  };
};
export const getSubscriptionList = (companyId: any, calledFrom: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingSubscriptionList(true));
      var compId =
        companyId !== "" && companyId !== undefined ? companyId : null;
      subscriptionService
        .getSubscriptionList(compId)
        .then((result) => {
          var data = JSON.parse(result);
          //console.log(data);
          let filter_Ref_Objects = data.filter(
            (obj: any) => obj.subscription.$ref
          );

          let filter_UserObject = data.filter(
            (obj: any) => !obj.subscription.$ref
          );
          //console.log(filter_UserObject, filter_Ref_Objects, 'seee')
          let dataList_containsUsers: any[];
          if (filter_UserObject.length > 0) {
            dataList_containsUsers = filter_UserObject.filter((x: any) => {
              if (x.subscription.Company) {
                if (x.subscription.Company.Subscriptions.length !== 0) {
                  return x;
                }
              }
            });
          }
          //console.log(data)
          if (!data.Message) {
            if (filter_Ref_Objects.length) {
              var filterRecords = data.map((element: any) => {
                var temp = Object.assign({}, element);
                if (temp.subscription.$ref) {
                  var refrencedData = filterRefDataFromJSONObject(
                    dataList_containsUsers,
                    temp.subscription.$ref,
                    "Subscription"
                  );
                  temp.subscription = refrencedData;
                  temp.userAssigned = 0;
                  return temp;
                } else {
                  return temp;
                }
              });
              data = filterRecords;
            }
            dispatch({
              subscriptionList: data,
              type: index.CharacterActionTypes.SUBSCRIPTION_LIST,
            });
            dispatch<any>(getSubscriptionListAction(data));
            if (calledFrom === "SubscriptionsLength") {
              dispatch({
                subscriptionListLength: data.length,
                type: index.CharacterActionTypes.SUBSCRIPTION_LIST_LENGTH,
              });
            }
            setTimeout(() => {
              dispatch<any>(pendingSubscriptionList(false));
            }, 500);
          } else {
            dispatch<any>(
              displayErrorListMessage(data.Message + ":Subscription")
            );
            dispatch<any>(pendingSubscriptionList(false));
          }
        })
        .catch((err) => {
          dispatch<any>(pendingSubscriptionList(false));
        });
    } catch (error) {
      dispatch<any>(pendingSubscriptionList(false));
    }
  };
};
function filterRefDataFromJSONObject(data: any, ref: any, calledFrom: any) {
  let selected_Object: any = {};
  if (calledFrom === "Subscription") {
    for (let index = 0; index < data.length; index++) {
      ////debugger;
      selected_Object = data[index].subscription.Company.Subscriptions.filter(
        (x: any) => x.$id === ref
      )[0];
      if (selected_Object !== {}) {
        break;
      }
    }
  } else {
    for (let index = 0; index < data.length; index++) {
      ////debugger;
      selected_Object = data[index].Company.Subscriptions.filter(
        (x: any) => x.$id === ref
      )[0];
      if (selected_Object !== {}) {
        break;
      }
    }
  }
  return selected_Object;
}
function filterRefDataFromJSONObject_Users(data: any, ref: any) {
  let selected_Object: any = {};
  for (let index = 0; index < data.length; index++) {
    selected_Object = data[index].Subscription.Users.filter(
      (x: any) => x.$id === ref
    )[0];
    if (selected_Object !== {} && selected_Object !== undefined) {
      break;
    }
  }
  return selected_Object;
}
export const getSubscriptListLength = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(getSubscriptionList("", "SubscriptionsLength"));
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));

      setTimeout(() => {
        dispatch<unknown | any>(errorOccurred(""));
      }, 3000);
    }
  };
};

export const addUpdateSubscription = (
  type: string,
  data: {},
  companyId: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      subscriptionService
        .addUpdateService(type, data)
        .then((response) => {
          var data = response;//&& JSON.parse(response);
          //console.log(data);
          if(data.includes('One or more errors occurred.')){ //(data.Message) {
            {data= data.split('One or more errors occurred. ').pop().replace(/[()]/g, '');
            dispatch<any>(errorOccurred(data));
            dispatch<any>(showHideList(false));}
          } else {
            dispatch<any>(savedData(true));
            dispatch<any>(showHideList(true));
            // console.log(data, 'yes')
            if (companyId !== "") {
              dispatch<any>(getSubscriptionList(companyId, "company"));
            } else {
              dispatch<any>(getSubscriptionList("", ""));
            }
            dispatch<any>(searchBox_Value_InTables(""));
          }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(savedData(false));
          }, 3000);
        })
        .catch((err) => {
          //debugger;
          dispatch<any>(errorOccurred(err));
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
          }, 3000);
        });
    } catch (error) {
      //debugger;
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};

// #3461  //Maksud

export const updateSubscriptionContactLookup = (
  subscriptionId: number,
  tenantId: string,
  enabled: boolean
) => {
  return async (dispatch: Dispatch) => {
    try {

      const updateContactLokup = await subscriptionService.updateContactLookupService(subscriptionId,tenantId,enabled)
      //debugger;
      
      if (!(updateContactLokup.status === 204 || updateContactLokup.status === 200)) {

        //debugger;
        const responseText = await updateContactLokup.text();
        dispatch<any>(
          errorOccurred(
            updateContactLokup.statusText || responseText ||
              "Error update subscription tenant id , seek technical assistance"
          )
        );
        dispatch<any>(showHideList(false));
      } else {        
        dispatch<any>(savedData(true));
        dispatch<any>(showHideList(true));
      }
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
        dispatch<any>(savedData(false));
      }, 3000);
      
    } catch (error) {
 
      const err = error as any;
    alert(error)
    alert(err)

      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};

export const displayErrorListMessage = (errorListMessage: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.DISPLAY_ERROR_LIST_MESSAGE,
      errorListMessage: errorListMessage,
    });
  };
};
export const deleteUserByEmail = (
  email: string,
  compId: any,
  deleteFrom: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      
      const removeUser = await userService.removeUser(email);
      //const removeUser = await userService.removeAllUserData(email);

          if (!(removeUser.status === 204 || removeUser.status === 200)) {
            const responseText = await removeUser.text();
            dispatch<any>(
              errorOccurred(
                removeUser.statusText || responseText ||
                  `This ${
                    deleteFrom === "GroupDelete" ? "group" : "user"
                  } is assigned to a Shared/Group inbox.  Remove from the Group and try again`
              )
            );
            dispatch<any>(showHideList(false));
          } else {
            dispatch<any>(deletedSuccessfullyMessage(true));
            dispatch<any>(showHideListUser(true));
            dispatch<any>(searchBox_Value_InTables(""));
            if (deleteFrom === "GroupDelete") {
              if (compId !== null) {
                dispatch<any>(displaySelectedGroupName(""));
                dispatch<any>(hideGroupEmailHeading(true));
                dispatch<any>(hideCompanyTab(true));
                dispatch<any>(hideGroupNonMemberTab(false));
                dispatch<any>(
                  getGroupListByCompanyAndSubscriptionId(null, compId, "")
                );
              } else {
                dispatch<any>(displaySelectedGroupName(""));
                dispatch<any>(hideGroupNonMemberTab(false));
                dispatch<any>(
                  getGroupListByCompanyAndSubscriptionId(null, null, "")
                );
              }
            } else {
              dispatch<any>(getUserList(null, compId));
            }
          }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(deletedSuccessfullyMessage(false));
          }, 3000);
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};

export const deleteAllUserDataByEmail = (
  email: string,
  compId: any,
  deleteFrom: any,
  confirm:boolean,
) => {
  return async (dispatch: Dispatch) => {
    try {
      const removeUser = await userService.removeAllUserData(email,confirm);
          if (!(removeUser.status === 204 || removeUser.status === 200)) {
            const responseText = await removeUser.text();
            dispatch<any>(
              errorOccurred(
                removeUser.statusText || responseText ||
                  `This ${
                    deleteFrom === "GroupDelete" ? "group" : "user"
                  } is assigned to a Shared/Group inbox.  Remove from the Group and try again`
              )
            );
            dispatch<any>(showHideList(false));
          } else {
            dispatch<any>(deletedSuccessfullyMessage(true));
            dispatch<any>(showHideListUser(true));
            dispatch<any>(searchBox_Value_InTables(""));
            if (deleteFrom === "GroupDelete") {
              if (compId !== null) {
                dispatch<any>(displaySelectedGroupName(""));
                dispatch<any>(hideGroupEmailHeading(true));
                dispatch<any>(hideCompanyTab(true));
                dispatch<any>(hideGroupNonMemberTab(false));
                dispatch<any>(
                  getGroupListByCompanyAndSubscriptionId(null, compId, "")
                );
              } else {
                dispatch<any>(displaySelectedGroupName(""));
                dispatch<any>(hideGroupNonMemberTab(false));
                dispatch<any>(
                  getGroupListByCompanyAndSubscriptionId(null, null, "")
                );
              }
            } else {
              dispatch<any>(getUserList(null, compId));
            }
          }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(deletedSuccessfullyMessage(false));
          }, 3000);
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};

export const deleteSubscriptionBySubscriptionId = (
  parameter: number,
  companyId: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const removeSubscription = await subscriptionService.removeSubscription(parameter);

          //console.log(response);
          if (!(removeSubscription.status === 204 || removeSubscription.status === 200)) {
            const responseText = await removeSubscription.text();
            dispatch<any>(
              errorOccurred(
                removeSubscription.statusText || responseText ||
                  "Error deleting subscription, seek technical assistance"
              )
            );
            dispatch<any>(showHideList(false));
          } else {
            dispatch<any>(searchBox_Value_InTables(""));

            dispatch<any>(deletedSuccessfullyMessage(true));
            dispatch<any>(showHideList(true));
            if (companyId !== "") {
              dispatch<any>(getSubscriptionList(companyId, "company"));
            } else {
              dispatch<any>(getSubscriptionList("", ""));
            }
          }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(deletedSuccessfullyMessage(false));
          }, 3000);
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const deleteCompanyByCompanyId = (parameter: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const removeCompany = await companyService.removeCompany(parameter);

          if (!(removeCompany.status === 204 || removeCompany.status === 200)) {
            const responseBody = await removeCompany.text();

            dispatch<any>(
              errorOccurred(
                removeCompany.statusText || responseBody ||
                  "This company has subscription(s), remove and try again"
              )
            );
            dispatch<any>(showHideList(false));
            setTimeout(() => {
              dispatch<any>(errorOccurred(""));

            }, 3000);
          } else {
            dispatch<any>(searchBox_Value_InTables(""));
            dispatch<any>(deletedSuccessfullyMessage(true));
            dispatch<any>(showHideListCompany(true));
            dispatch<any>(getCompanyList("", [], ""));
            dispatch<any>(displaySelectedCompanyName(""));
          
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(deletedSuccessfullyMessage(false));
          }, 3000);
        }
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const hideCompanyTab = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      hideCompanyTab: value,
      type: index.CharacterActionTypes.HIDE_COMPANY_TAB,
    });
  };
};
export const hideGroupNonMemberTab = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      hideGroupNonMemberTab: value,
      type: index.CharacterActionTypes.HIDE_GROUP_NON_MEMBER_TAB,
    });
  };
};
export const groupEditObject = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      groupEditObject: value,
      type: index.CharacterActionTypes.GROUP_EDIT_OBJECT,
    });
  };
};
export const addUpdateNumberAction = (
  data: any,
  searchBy: any,
  savingFrom: any,
  countryCode: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      numberService
        .addUpdateNumberService(data)
        .then((response) => {
          var dataResponse = JSON.parse(response);
          if (dataResponse.Message) {
            dispatch<any>(errorOccurred(dataResponse.Message));
            dispatch<any>(showHideNumberList(false));
          } else {
            dispatch<any>(savingNumberToDataBase(false));
            setTimeout(() => {
              dispatch<any>(savingNumberToDataBase(""));
            }, 3000);
            dispatch<any>(purchasedPhoneNumber(data[0].PhoneNumber));
            dispatch<any>(changeButtonText("Update Changes"));

            if (savingFrom === "Numbers") {
              dispatch<any>(showHideNumberList(true));
              dispatch<any>(filterNumberBy(searchBy));
              dispatch<any>(getPhoneNumerList(null, searchBy));
            }
          }
          //else {
          //     ///Navigatr to associate Route
          //    dispatch<any>(navigateToDefinedRoute(savingFrom, data, countryCode, searchBy))
          // }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(savedData(false));
          }, 3000);
        })
        .catch((err) => {
          dispatch<any>(errorOccurred(err));
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
          }, 3000);
        });
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const updateNumberAction = (
  data: any,
  searchBy: any,
  savingFrom: any,
  countryCode: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      numberService
        .addUpdateNumberService(data)
        .then((response) => {
          var dataResponse = JSON.parse(response);
          if (dataResponse.Message) {
            dispatch<any>(errorOccurred(dataResponse.Message));
            dispatch<any>(showHideNumberList(false));
          } else {
            ///Navigatr to associate Route
            dispatch<any>(
              navigateToDefinedRoute(savingFrom, data, countryCode, searchBy)
            );
          }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(savedData(false));
          }, 3000);
        })
        .catch((err) => {
          dispatch<any>(errorOccurred(err));
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
          }, 3000);
        });
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const purchasedPhoneNumber = (phoneNumber: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      phoneNumberPurchased: phoneNumber,
      type: index.CharacterActionTypes.PHONE_NUMBER_PURCHASED,
    });
  };
};
export const changeButtonText = (buttonText: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      buttonTextReducer: buttonText,
      type: index.CharacterActionTypes.CHANGE_BUTTON_TEXT,
    });
  };
};
export const navigateToDefinedRoute = (
  savingFrom: any,
  data: any,
  countryCode: any,
  searchBy: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      if (savingFrom === "Twillio" || savingFrom === "Numbers") {
        dispatch<any>(savingNumberToDataBase(false));
        setTimeout(() => {
          dispatch<any>(savingNumberToDataBase(""));
        }, 3000);
        dispatch<any>(purchasedPhoneNumber(data[0].PhoneNumber));
        dispatch<any>(changeButtonText("Update Changes"));
        dispatch<any>(showHideNumberList(true));
        dispatch<any>(filterNumberBy(searchBy));
        dispatch<any>(getPhoneNumerList(null, searchBy));
      } else if (savingFrom === "page" || savingFrom === "Edit Numbers") {
        dispatch<any>(savedData(true));
        dispatch<any>(showHideNumberList(true));
        dispatch<any>(filterNumberBy(searchBy));
        dispatch<any>(getPhoneNumerList(null, searchBy));
      } else if (
        savingFrom === "Company:add" ||
        savingFrom === "Company:edit" ||
        savingFrom === "Company:editGroup" ||
        savingFrom === "Company:addGroup"
      ) {
        setTimeout(() => {
          dispatch<any>(savingNumberToDataBase(""));
        }, 3000);
        dispatch<any>(showHideNumberList(true));
        dispatch<any>(savedData(true));
        dispatch<any>(showNumberInUniqueNumber(data[0].PhoneNumber));
        dispatch<any>(defaultCountryCodeForUser(countryCode));
        history.push("Company", { callingFrom: savingFrom });
      }
      dispatch<any>(searchBox_Value_InTables(""));
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const deleteNumberConfirmation = (
  parameter: any,
  searchBy: any,
  callingFrom: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      if (parameter[0].Type === 0) {
        dispatch<any>(
          getNumberInfoFromTwillio(parameter, searchBy, callingFrom)
        );

        dispatch<any>(
          deleteNumberByNumberModel(parameter, searchBy, callingFrom)
        );
      } else {
        dispatch<any>(
          deleteNumberByNumberModel(parameter, searchBy, callingFrom)
        );
      }
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const deleteNumberByNumberModel = (
  parameter: any,
  searchBy: any,
  callingFrom: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      numberService
        .removeNumbers(parameter)
        .then((response) => {
          //console.log(response);
          var result = JSON.parse(response);
          //console.log(result, "result");
          if (result.Message) {
            dispatch<any>(
              errorOccurred(
                "This number is currently in use, remove and try again"
              )
            );
            dispatch<any>(showHideList(false));
            dispatch<any>(showWarningMessage(""));
          } else {
            dispatch<any>(searchBox_Value_InTables(""));

            if (callingFrom === "Numbers") {
              dispatch<any>(deletedSuccessfullyMessage(true));
              dispatch<any>(showHideNumberList(true));
              dispatch<any>(getPhoneNumerList(null, searchBy));
            } else {
              dispatch<any>(showNumberInUniqueNumber(""));
              dispatch<any>(defaultCountryCodeForUser(""));
            }
          }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(deletedSuccessfullyMessage(false));
          }, 3000);
        })
        .catch((err) => {
          dispatch<any>(errorOccurred(err));
        });
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const pendingGroupListBySubIdAndCompID = (param: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.PENDING_GROUP_LIST_BY_SUBID_COMPID,
      pendingGroupListBySubIdAndCompId: param,
    });
  };
};
export const getGroupListFilter = (list: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      groupListFilter: list,
      type: index.CharacterActionTypes.GROUP_LIST_FILTER,
    });
  };
};
export const showGroupSideNavClick = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      showGroupTab: value,
      type: index.CharacterActionTypes.GORUP_SIDE_NAV_CLICK,
    });
  };
};
export const EmailEnabledForUser = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      emailEnabled: value,
      type: index.CharacterActionTypes.EMAIL_ENABLED_FOR_USER,
    });
  };
};
export const getGroupListByCompanyAndSubscriptionId = (
  subscriptionId: any,
  companyId: any,
  type: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingGroupListBySubIdAndCompID(true));
      groupService
        .getGroupListByCompanyAndSubscriptionId(subscriptionId, companyId)
        .then((result) => {
          var data = JSON.parse(result);
          if (!data.Message) {
            var filter_Ref_Objects = data.filter(
              (obj: any) => obj.$ref !== undefined
            );
            var filter_GroupObjectObject = data.filter(
              (obj: any) => obj.$ref === undefined
            );
            var dataList_containsSubscription = filter_GroupObjectObject.filter(
              (x: any) => x.Subscription !== undefined
            );
            if (filter_Ref_Objects.length !== 0) {
              var filterRecords = data.map((element: any) => {
                var temp = Object.assign({}, element);
                if (temp.$ref !== undefined) {
                  var refrenceData = filterRefDataFromJSONObject_Users(
                    dataList_containsSubscription,
                    temp.$ref
                  );
                  refrenceData.backGroundColor =
                    commonMethod.randomColorGenerator();
                  return refrenceData;
                } else {
                  temp.backGroundColor = commonMethod.randomColorGenerator();
                  return temp;
                }
              });
              data = filterRecords;
            } else {
              var list = data.map((element: any) => {
                element.backGroundColor = commonMethod.randomColorGenerator();
                return element;
              });
              data = list;
            }
            var filteredList =
              data.length !== 0
                ? data.filter((x: any) => x.Disabled === false)
                : data;
            dispatch<any>(getGroupListFilter(filteredList));
            if (type !== "edit") {
              if (filteredList.length !== 0) {
                dispatch<any>(groupEditObject(filteredList[0]));
                dispatch<any>(setFilteredObjectGroup(filteredList[0]));
                dispatch<any>(
                  displaySelectedGroupName(
                    filteredList[0].UserName !== ""
                      ? filteredList[0].UserName
                      : filteredList[0].Email
                  )
                );
                dispatch<any>(selectedGroupEmail(filteredList[0].Email));
                dispatch<any>(getGroupMemberListByEmail(filteredList[0].Email));
              } else {
                dispatch<any>(pendingGroupMemberListByEmail(false));
                dispatch<any>(displaySelectedGroupName(""));
                dispatch<any>(getGroupMemberListAction([]));
                dispatch<any>(getGroupMemberListFilter([]));
              }
            }
            dispatch({
              groupListByCompAndSubId: data,
              type: index.CharacterActionTypes.GROUP_LIST_BY_SUBID_COMPID,
            });
            setTimeout(() => {
              dispatch<any>(pendingGroupListBySubIdAndCompID(false));
            }, 500);
          } else {
            dispatch<any>(displayErrorListMessage(data.Message + ":Groups"));
          }
          dispatch<any>(pendingGroupListBySubIdAndCompID(false));
        })
        .catch((err) => {
          dispatch<any>(pendingGroupListBySubIdAndCompID(false));
        });
    } catch (error) {
      dispatch<any>(pendingGroupListBySubIdAndCompID(false));
    }
  };
};
export const setFilteredObjectGroup = (object: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(emailForUser(object.Email));
      dispatch<any>(nameForUser(object.UserName));
      dispatch<any>(registerationDateForUser(object.RegistrationDate));
      dispatch<any>(defaultCountryCodeForUser(object.DefaultCountryCode));
      dispatch<any>(storeTextForUser(object.StoreText));
      dispatch<any>(disabledForUser(object.Disabled));
      dispatch<any>(creditLimitForUser(object.CreditLimit));
      dispatch<any>(usedCreditForUser(object.UsedCredit));
      dispatch<any>(showNumberInUniqueNumber(object.UniqueNumber));
    } catch (Error) {
      const err = Error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const pendingGroupMemberListByEmail = (param: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.PENDING_MEMBER_LIST_BY_EMAIL,
      pendingGroupMemberListByEmail: param,
    });
  };
};
export const pendingGroupNotMemberListByEmail = (param: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.PENDING_NOT_MEMBER_LIST_BY_EMAIL,
      pendingnotGroupNotMemberListByEmail: param,
    });
  };
};
export const getGroupMemberListAction = (list: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      groupMemberList: list,
      type: index.CharacterActionTypes.GROUP_MEMBER_LIST_BY_EMAIL,
    });
  };
};
export const getNotGroupMemberListAction = (list: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      groupNotMemberList: list,
      type: index.CharacterActionTypes.GROUP_NOT_MEMBER_LIST_BY_EMAIL,
    });
  };
};
export const showIcnOnGroupUserTab = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      iconName: value,
      type: index.CharacterActionTypes.ICON_NAME_GROUP_NON_GROUP_MEMBERS,
    });
  };
};
export const showHideSelectAll = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      showHideSelectAllIcon: value,
      type: index.CharacterActionTypes.HIDE_SHOW_SELECT_ALL,
    });
  };
};
export const showHideSelectAllNotGroupMember = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      showHideSelectAllIconNotGroupList: value,
      type: index.CharacterActionTypes.HIDE_SHOW_SELECT_ALL_NOT_GROUP_MEMBER,
    });
  };
};
export const getGroupMemberListFilter = (list: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      filteredGroupMemberList: list,
      type: index.CharacterActionTypes.FILTERED_GROUP_MEMBER_LIST,
    });
  };
};
export const getGroupMemberNotListFilter = (list: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      filteredNonGroupMemberList: list,
      type: index.CharacterActionTypes.FILTERED_NON_GROUP_MEMBER_LIST,
    });
  };
};
export const hideGroupEmailHeading = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      hideGroupEmailHeading: value,
      type: index.CharacterActionTypes.HIDE_GROUP_EMAIL_HEADING,
    });
  };
};
export const selectedGroupEmail = (email: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      selectedGroupEmailAction: email,
      type: index.CharacterActionTypes.SELECTED_GROUP_EMAIL,
    });
  };
};
export const getGroupMemberListByEmail = (email: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingGroupMemberListByEmail(true));
      groupService
        .getGroupMemberListByGroupEmail(email)
        .then((result) => {
          var data = JSON.parse(result);
          if (!data.Message) {
            var filter_Ref_Objects = data.filter(
              (obj: any) => obj.$ref !== undefined
            );
            var filter_UserObject = data.filter(
              (obj: any) => obj.$ref === undefined
            );
            var dataList_containsSubscription = filter_UserObject.filter(
              (x: any) => x.Subscription !== undefined
            );
            if (filter_Ref_Objects.length !== 0) {
              var filterRecords = data.map((element: any) => {
                var temp = Object.assign({}, element);
                if (temp.$ref !== undefined) {
                  var refrenceData = filterRefDataFromJSONObject_Users(
                    dataList_containsSubscription,
                    temp.$ref
                  );
                  refrenceData.backGroundColor =
                    commonMethod.randomColorGenerator();
                  refrenceData.checked = false;
                  refrenceData.groupMember = true;

                  return refrenceData;
                } else {
                  temp.checked = false;
                  temp.groupMember = true;
                  temp.backGroundColor = commonMethod.randomColorGenerator();
                  return temp;
                }
              });
              data = filterRecords;
            } else {
              var list = data.map((x: any) => {
                x.checked = false;
                x.backGroundColor = commonMethod.randomColorGenerator();
                x.groupMember = true;
                return x;
              });
              data = list;
            }
            dispatch<any>(getGroupMemberListAction(data));
            dispatch<any>(getGroupMemberListFilter(data));
          }
          setTimeout(() => {
            dispatch<any>(pendingGroupMemberListByEmail(false));
          }, 500);
        })
        .catch((err) => {
          dispatch<any>(pendingGroupMemberListByEmail(false));
        });
    } catch (error) {
      dispatch<any>(pendingGroupMemberListByEmail(false));
    }
  };
};
export const getGroupNotMemberListByEmail = (email: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingGroupNotMemberListByEmail(true));
      groupService
        .getGroupNotMemberListByGroupEmail(email)
        .then((result) => {
          var data = JSON.parse(result);
          if (!data.Message) {
            var list = data.map((x: any) => {
              x.checked = false;
              x.backGroundColor = commonMethod.randomColorGenerator();
              x.groupMember = false;
              return x;
            });
            dispatch<any>(getNotGroupMemberListAction(list));
            dispatch<any>(getGroupMemberNotListFilter(list));
          }

          setTimeout(() => {
            dispatch<any>(pendingGroupNotMemberListByEmail(false));
          }, 500);
        })
        .catch((err) => {
          dispatch<any>(pendingGroupNotMemberListByEmail(false));
        });
    } catch (error) {
      dispatch<any>(pendingGroupNotMemberListByEmail(false));
    }
  };
};
export const getLoggedInUserAccessLevel = (loggedinUser: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      loggedinUserAccessLevel: loggedinUser,
      type: index.CharacterActionTypes.GET_ACCESS_LEVEL_LOGGED_IN_USER,
    });
  };
};
export const hideHeaderLoader = (loaderValue: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      loaderValueHeader: loaderValue,
      type: index.CharacterActionTypes.LOADER_HEADER,
    });
  };
};
export const getAccessLeveApi = (email: any) => {
  return async (dispatch: Dispatch) => {
    try { 
      loggedInUserEmail(email);
      userService
        .getAccessLevel()
        .then((result) => {
          var data = JSON.parse(result);
          //console.log(data);
          if (!data.Message) {
            dispatch<any>(hideHeaderLoader(false));
            dispatch<any>(getLoggedInUserAccessLevel(data));
          } else {
            dispatch<any>(getLoggedInUserAccessLevel(50));
          }
        })
        .catch((err) => {
          dispatch<any>(pendingGroupMemberListByEmail(false));
        });
    } catch (error) {
      dispatch<any>(pendingGroupMemberListByEmail(false));
    }
  };
};
export const addUsernGroupByEmail = (list: any, email: any) => {
  return async (dispatch: Dispatch) => {
    try {
      list.forEach((element: any) => {
        groupService
          .addGroupMemberListByGroupEmail(element, email)
          .then((response) => {
            var data = JSON.parse(response);
            if (data.Message) {
              dispatch<any>(getGroupMemberListByEmail(email));
              dispatch<any>(getGroupNotMemberListByEmail(email));
              dispatch<any>(errorOccurred(data.Message));
              dispatch<any>(savedData(false));
              setTimeout(() => {
                dispatch<any>(errorOccurred(""));
                dispatch<any>(savedData(false));
              }, 3000);
              return false;

              // return false
            } else {
              element.groupMember = true;
              dispatch<any>(savedData(true));
            }
            setTimeout(() => {
              dispatch<any>(errorOccurred(""));
              dispatch<any>(savedData(false));
            }, 3000);
          })
          .catch((err) => {
            dispatch<any>(errorOccurred(err));
            setTimeout(() => {
              dispatch<any>(errorOccurred(""));
            }, 3000);
          });
      });
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const filterNumberBy = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      filterNumbersBY: value,
      type: index.CharacterActionTypes.FILTER_NUMBERS,
    });
  };
};
export const servicePlan = (list: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      servicePlanList: list,
      type: index.CharacterActionTypes.SERVICE_PLAN_LIST,
    });
  };
};
export const getServicePlans = () => {
  return async (dispatch: Dispatch) => {
    try {
      subscriptionService
        .getServicePlanList()
        .then((result) => {
          var data = JSON.parse(result);
          if (!data.Message) {
            dispatch<any>(servicePlan(data));
          } else {
            dispatch<any>(
              displayErrorListMessage(data.Message + ":ServicePlan")
            );
          }
        })
        .catch((err) => {
          dispatch<any>(displayErrorListMessage(err + ":ServicePlan"));
        });
    } catch (error) {
      dispatch<any>(pendingSubscriptionList(false));
    }
  };
};
export const unassignMemberFromGroup = (list: any, email: any) => {
  return async (dispatch: Dispatch) => {
    try {
      list.forEach((element: any) => {
        groupService
          .removeMemberGroup(email, element.Email)
          .then((result) => result)
          .then((data) => {
            //console.log(data);
            if (data.status !== 200 && data.status !== 204) {
              //var result = data.text();

              //var errorMessage = JSON.parse(result);
              dispatch<any>(
                errorOccurred(data.statusText || "Request cannot be completed")
              );
              dispatch<any>(savedData(false));
              setTimeout(() => {
                dispatch<any>(errorOccurred(""));
                dispatch<any>(savedData(false));
              }, 3000);
              return false;
            } else {
              element.groupMember = false;
              dispatch<any>(savedData(true));
            }
            setTimeout(() => {
              dispatch<any>(errorOccurred(""));
              dispatch<any>(savedData(false));
            }, 3000);
          })
          .catch((err) => {
            dispatch<any>(errorOccurred(err));
            setTimeout(() => {
              dispatch<any>(errorOccurred(""));
            }, 3000);
          });
      });
    } catch (error) {
      const err = error as any;
      dispatch<any>(errorOccurred(err));
      setTimeout(() => {
        dispatch<any>(errorOccurred(""));
      }, 3000);
    }
  };
};
export const loggedIUserDetail = (object: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      userDetail: object,
      type: index.CharacterActionTypes.LOGGED_IN_USER_DETAIL,
    });
  };
};
export const pendingLoginUserDetail = (result: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      pendingResult: result,
      type: index.CharacterActionTypes.PENDING_LOGGED_IN_RESULT,
    });
  };
};
export const loggedInUserDisabled = (result: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      loggedInUserDisable: result,
      type: index.CharacterActionTypes.LOGGED_IN_USER_DISABELD,
    });
  };
};

export const loggedInUserEmail = (email: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingLoginUserDetail(true));
      userService
        .loggedInUserDetail()
        .then((result) => {
          var userDetails = JSON.parse(result);
          if (!userDetails.Message) {
            if (userDetails.Disabled === true) {
              dispatch<any>(loggedInUserDisabled(true));
              setTimeout(() => {
                authContext.logOut();
              }, 15000);
            } else {
              ////debugger;
              if (userDetails.WhiteLabelBrand !== undefined) {
                dispatch<any>(
                  getWhiteLabelLoggedInUser(userDetails.WhiteLabelBrand)
                );
              } else {
                dispatch<any>(getWhiteLabelLoggedInUser(""));
              }
              dispatch<any>(emailForUser(userDetails.Email));
              dispatch<any>(nameForUser(userDetails.UserName));
              dispatch<any>(creditLimitForUser(userDetails.CreditLimit));
              dispatch<any>(
                registerationDateForUser(userDetails.RegistrationDate)
              );
              dispatch<any>(roleIdForUser(userDetails.Role));
              dispatch<any>(
                defaultCountryCodeForUser(userDetails.DefaultCountryCode)
              );
              dispatch<any>(showNumberInUniqueNumber(userDetails.UniqueNumber));
              dispatch<any>(usedCreditForUser(userDetails.UsedCredit));
              dispatch<any>(storeTextForUser(userDetails.StoreText));
              dispatch<any>(disabledForUser(userDetails.Disabled));
              dispatch<any>(groupForUser(userDetails.IsGroup));
              dispatch<any>(
                uniqueNumberCostForUser(userDetails.UniqueNumberCost)
              );
              dispatch<any>(loggedIUserDetail(userDetails));
              dispatch<any>(
                myCompanyPartnerParentId(
                  userDetails.Subscription.Company.ParentId
                )
              );
              dispatch<any>(pendingLoginUserDetail(false));
            }
          } else {
            dispatch<any>(
              displayErrorListMessage(userDetails.Message + ":Users")
            );
            dispatch<any>(pendingLoginUserDetail(false));
          }
        })
        .catch((err) => {
          setTimeout(() => {
            dispatch<any>(pendingUserList(false));
            dispatch<any>(pendingLoginUserDetail(false));
          }, 500);
        });
    } catch (error) {
      setTimeout(() => {
        dispatch<any>(pendingUserList(false));
        dispatch<any>(pendingLoginUserDetail(false));
      }, 500);
    }
  };
};
export const myCompanyPartnerParentId = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyPartnerParent: value,
      type: index.CharacterActionTypes.MY_COMPANY_PARTNER_PARENT_ID,
    });
  };
};
export const pendingNumberFromTwillio = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      pendingResultFromTwillio: value,
      type: index.CharacterActionTypes.PENDING_RESULT_FROM_TWILLIO,
    });
  };
};
export const localNumberListTwillio = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      localNumberListTwillio: value,
      type: index.CharacterActionTypes.LOCAL_NUMBER_LIST_TWILLIO,
    });
  };
};
export const MobileNumberListTwillio = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      mobileNumberListTwillio: value,
      type: index.CharacterActionTypes.MOBILE_NUMBER_LIST_TWILLIO,
    });
  };
};
export const fetchNumbers = (countryCode: any, type: any, areaCode: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingNumberFromTwillio(true));
      let extraParam = "";
      if (countryCode === "GB" || countryCode === "AU") {
        if (areaCode === "") {
          extraParam = "SmsEnabled=true";
        } else {
          extraParam = "SmsEnabled=true&AreaCode=" + areaCode;
        }
      } else {
        if (areaCode === "") {
          extraParam = "SmsEnabled=true&MmsEnabled=true";
        } else {
          extraParam = "SmsEnabled=true&MmsEnabled=true&AreaCode=" + areaCode;
        }
      }
      numberService
        .getPhoneNumberFromTwillio(countryCode, type, extraParam)
        .then((result) => {
          var convertJsonData = JSON.parse(result);
          dispatch<any>(pendingNumberFromTwillio(false));
          if (type === "MOBILE") {
            dispatch<any>(showLocalResponse(false));
            dispatch<any>(localNumberListTwillio([]));
            dispatch<any>(
              MobileNumberListTwillio(convertJsonData.available_phone_numbers)
            );
          } else {
            dispatch<any>(showLocalResponse(true));
            dispatch<any>(MobileNumberListTwillio([]));
            dispatch<any>(
              localNumberListTwillio(convertJsonData.available_phone_numbers)
            );
          }
        })
        .catch((err: any) => {
          dispatch<any>(errorOccurred(err));
          dispatch<any>(pendingNumberFromTwillio(false));
          setTimeout(() => {
            dispatch<any>(errorOccurred(err));
          }, 3000);
        });
    } catch (error) {
      dispatch<any>(pendingNumberFromTwillio(false));
    }
  };
};
export const savingNumberToDataBase = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      saveTwillioNumberToDatabase: value,
      type: index.CharacterActionTypes.SAVE_TWILLIO_NUMBER_TO_DATABASE,
    });
  };
};
export const buyNumberFromTwillio = (
  phoneNumber: any,
  smsUrl: any,
  twilBin: any,
  callingFrom: any,
  countryCode: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      numberService
        .provisionPhoneNumberFromTwillio(phoneNumber, smsUrl, twilBin)
        .then((result) => {
          var data = JSON.parse(result);
          if (data.status === "in-use") {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(savedData(true));
            var objToSaveNumberInDatabase = [
              {
                PhoneNumber: phoneNumber,
                Type: 0,
              },
            ];
            dispatch<any>(savingNumberToDataBase(true));
            ///Save already purchased number to database
            dispatch<any>(
              savePurchasedNumberToDatabase(
                callingFrom,
                objToSaveNumberInDatabase,
                countryCode
              )
            );
          } else {
            dispatch<any>(errorOccurred(data.message));
            dispatch<any>(savedData(false));
          }
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(savedData(false));
          }, 3000);
        })
        .catch((err: any) => {});
    } catch (error) {
      //  dispatch<any>(pendingNumberFromTwillio(false))
    }
  };
};
export const savePurchasedNumberToDatabase = (
  callingFrom: any,
  objToSaveNumberInDatabase: any,
  countryCode: any
) => {
  return async (dispatch: Dispatch) => {
    if (callingFrom === "EditUsers") {
      dispatch<any>(
        addUpdateNumberAction(
          objToSaveNumberInDatabase,
          "All",
          "EditUsers",
          countryCode
        )
      );
    } else if (callingFrom === "addUsers") {
      dispatch<any>(
        addUpdateNumberAction(
          objToSaveNumberInDatabase,
          "All",
          "addUsers",
          countryCode
        )
      );
    } else if (callingFrom === "addGroups") {
      dispatch<any>(
        addUpdateNumberAction(
          objToSaveNumberInDatabase,
          "All",
          "addGroups",
          countryCode
        )
      );
    } else if (callingFrom === "EditGroups") {
      dispatch<any>(
        addUpdateNumberAction(
          objToSaveNumberInDatabase,
          "All",
          "EditGroups",
          countryCode
        )
      );
    } else if (
      callingFrom === "Company:add" ||
      callingFrom === "Company:edit"
    ) {
      dispatch<any>(
        addUpdateNumberAction(
          objToSaveNumberInDatabase,
          "All",
          callingFrom,
          countryCode
        )
      );
    } else if (
      callingFrom === "Company:editGroup" ||
      callingFrom === "Company:addGroup"
    ) {
      dispatch<any>(
        addUpdateNumberAction(
          objToSaveNumberInDatabase,
          "All",
          callingFrom,
          countryCode
        )
      );
    } else {
      dispatch<any>(
        addUpdateNumberAction(
          objToSaveNumberInDatabase,
          "All",
          "Twillio",
          countryCode
        )
      );
    }
  };
};
export const pendingParentCompanyList = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      pendingResultFromParentCompanyList: value,
      type: index.CharacterActionTypes.PENDING_ARENT_COMPANY_LIST,
    });
  };
};
export const getParentCompaniesList = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingParentCompanyList(true));
      companyService
        .getParentCompanyList()
        .then((result) => {
          var data = JSON.parse(result);
          if (!data.Message) {
            dispatch({
              parentCompanyList: data,
              type: index.CharacterActionTypes.PARENT_COMPANY_LIST,
            });
            dispatch<any>(pendingParentCompanyList(false));
          } else {
            dispatch<any>(
              displayErrorListMessage(data.Message + ":ParentCompany")
            );
            dispatch<any>(pendingParentCompanyList(false));
          }
        })
        .catch((err) => {
          dispatch<any>(pendingParentCompanyList(false));
        });
    } catch (error) {
      dispatch<any>(pendingParentCompanyList(false));
    }
  };
};
export const twillioLogin = (countryCode: any, type: any, areaCode: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingNumberFromTwillio(true));
      numberService
        .twillioSign()
        .then((result) => {
          dispatch<any>(pendingNumberFromTwillio(true));
          dispatch<any>(localNumberListTwillio([]));
          dispatch<any>(MobileNumberListTwillio([]));
          dispatch<any>(fetchNumbers(countryCode, type, areaCode));
        })
        .catch((err) => {
          dispatch<any>(pendingParentCompanyList(false));
        });
    } catch (error) {
      dispatch<any>(pendingParentCompanyList(false));
    }
  };
};
export const toggleClassSidNav = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      toggleClassSidNav: value,
      type: index.CharacterActionTypes.TOGGLE_CLASS_SIDE_NAV,
    });
  };
};
export const showLocalResponse = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      showLocalResponse: value,
      type: index.CharacterActionTypes.LOCAL_RESPONSE_SHOW,
    });
  };
};
export const showNumberInUniqueNumber = (param: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: index.CharacterActionTypes.SHOW_UNIQUE_NUMBER,
      showUniqueNumber: param,
    });
  };
};
export const emailForUser = (email: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      emailForAddEditUser: email,
      type: index.CharacterActionTypes.EMAIL_FOR_ADD_EDIT_USER,
    });
  };
};
export const nameForUser = (name: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      nameForAddEditUser: name,
      type: index.CharacterActionTypes.NAME_FOR_ADD_EDIT_USER,
    });
  };
};
export const subscriptionIdForUser = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      subscriptionIdForAddEditUser: value,
      type: index.CharacterActionTypes.SUBSCRIPTION_ID_FOR_ADD_EDIT_USER,
    });
  };
};
export const subscriptionNameForUser = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      subscriptionNameForAddEditUser: value,
      type: index.CharacterActionTypes.SUBSCRIPTION_NAME_FOR_ADD_EDIT_USER,
    });
  };
};
export const uniqueNumberCostForUser = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      uniqueNumbetCostForAddEditUser: value,
      type: index.CharacterActionTypes.UNIQUE_NUMBER_COST_FOR_ADD_EDIT_USER,
    });
  };
};
export const creditLimitForUser = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      creditLimitForAddEditUser: value,
      type: index.CharacterActionTypes.CREDIT_LIMIT_FOR_ADD_EDIT_USER,
    });
  };
};
export const roleIdForUser = (roleId: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      roleIdForAddEditUser: roleId,
      type: index.CharacterActionTypes.ROLE_ID_FOR_ADD_EDIT_USER,
    });
  };
};
export const storeTextForUser = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      storeTextForAddEditUser: value,
      type: index.CharacterActionTypes.STORE_TEXT_FOR_ADD_EDIT_USER,
    });
  };
};
export const setCostCode = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      costCodeForAddEditUser: value,
      type: index.CharacterActionTypes.COST_CODE_FOR_ADD_EDIT_USER,
    });
  };
};
export const disabledForUser = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      disabledForAddEditUser: value,
      type: index.CharacterActionTypes.DISABLED_FOR_ADD_EDIT_USER,
    });
  };
};
//Task #3461 //Maksud
export const ContactLookupEnabledForUser = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      ContactLookupEnabledForAddEditUser: value,
      type: index.CharacterActionTypes.CONTACT_LOOKUP_ENABLED_EDIT_USER,
    });
  };
};

export const groupForUser = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      groupForAddEditUser: value,
      type: index.CharacterActionTypes.GROUP_FOR_ADD_EDIT_USER,
    });
  };
};
export const editUserObject = (userObj: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      objectUserData: userObj,
      type: index.CharacterActionTypes.USER_DETAIL_OBJ,
    });
  };
};
export const defaultCountryCodeForUser = (defaultCountryCode: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      defaultCountryCode: defaultCountryCode,
      type: index.CharacterActionTypes.DEFAULT_COUNTRY_CODE,
    });
  };
};
export const registerationDateForUser = (registerationDate: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      registerationDate: registerationDate,
      type: index.CharacterActionTypes.REGISTERATION_DATE_FOR_ADD_EDIT,
    });
  };
};
export const usedCreditForUser = (usedCredit: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      usedCredit: usedCredit,
      type: index.CharacterActionTypes.USED_CREDIT_FOR_ADD_EDIT_USER,
    });
  };
};
export const saveSelectedComapnyDetail = (object: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      savedCompanyDetail: object,
      type: index.CharacterActionTypes.SAVED_SELECTED_COMPANY_DETAIL,
    });
  };
};
export const hideUserTabFromCompanyDetail = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      hideUserTabCompanyDetail: value,
      type: index.CharacterActionTypes.HIDE_USER_LIST_FROM_COMPANY_TAB,
    });
  };
};
export const hideGroupTabFromCompanyDetail = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      hideGroupTabCompanyDetail: value,
      type: index.CharacterActionTypes.HIDE_GROUP_TAB_FROM_COMPANY_DETAIL,
    });
  };
};
export const currentActiveGroupTabName = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      activeGroupTabName: value,
      type: index.CharacterActionTypes.CURRENT_ACTIVE_TAB_NAME,
    });
  };
};
export const showWarningMessage = (phoneNumber: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      phoneNumber: phoneNumber,
      type: index.CharacterActionTypes.WARNING_PHONE_NUMBER,
    });
  };
};
export const getNumberInfoFromTwillio = (
  parameter: any,
  searchBy: any,
  callingFrom: any
) => {
  return async (dispatch: Dispatch) => {
    //console.log(parameter)
    numberService
      .getNumberInfoFromTwillio(parameter[0].PhoneNumber)
      .then((response) => {
        var dataResponse = JSON.parse(response);
        if (dataResponse.Message) {
          dispatch<any>(errorOccurred(dataResponse.Message));
          dispatch<any>(showHideNumberList(false));
          dispatch<any>(showWarningMessage(parameter[0].PhoneNumber));
        } else {
          if (dataResponse.incoming_phone_numbers !== undefined) {
            if (dataResponse.incoming_phone_numbers.length !== 0) {
              dispatch<any>(
                deleteNumberFromTwillio(
                  dataResponse.incoming_phone_numbers[0].sid
                )
              );
              dispatch<any>(
                deleteNumberByNumberModel(
                  parameter[0].PhoneNumber,
                  searchBy,
                  callingFrom
                )
              );
            } else {
              dispatch<any>(defaultCountryCodeForUser(0));
              dispatch<any>(showWarningMessage(parameter[0].PhoneNumber));
              dispatch<any>(showNumberInUniqueNumber(""));
            }
          } else {
            dispatch<any>(showWarningMessage(parameter[0].PhoneNumber));
            dispatch<any>(defaultCountryCodeForUser(0));
            dispatch<any>(showNumberInUniqueNumber(""));
          }
        }
        setTimeout(() => {
          dispatch<any>(errorOccurred(""));
          dispatch<any>(savedData(false));
        }, 3000);
      })
      .catch((err: any) => {
        dispatch<any>(errorOccurred(err));
        setTimeout(() => {
          dispatch<any>(errorOccurred(""));
        }, 3000);
      });
  };
};
export const deleteNumberFromTwillio = (parameter: any) => {
  return async (dispatch: Dispatch) => {
    numberService
      .releaseNumberFromTwillio(parameter)
      .then((response) => {
        if (response !== "") {
          var result = JSON.parse(response);
          dispatch<any>(errorOccurred(result.message));
        } else {
          dispatch<any>(defaultCountryCodeForUser(0));
          dispatch<any>(showNumberInUniqueNumber(""));
          dispatch<any>(deletedSuccessfullyMessage(true));
          setTimeout(() => {
            dispatch<any>(errorOccurred(""));
            dispatch<any>(deletedSuccessfullyMessage(false));
          }, 3000);
        }
      })
      .catch((err: any) => {
        dispatch<unknown | any>(errorOccurred(err));
        setTimeout(() => {
          dispatch<any>(errorOccurred(""));
        }, 3000);
      });
  };
};
export const userEditFromNumberBreadCrumb = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      userEditTabValue: value,
      type: index.CharacterActionTypes.USER_EDIT_BREADCRUMB_NUMBER,
    });
  };
};
export const pendingMyCompanyDetail = (result: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      pendingLoggedInCompanyDetail: result,
      type: index.CharacterActionTypes.PENDING_LOGGED_IN_COMPANY_DETAIL,
    });
  };
};
export const loggedInCompanyDetail = (email: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingMyCompanyDetail(true));
      companyService
        .getloggedInUserDetail()
        .then((result) => {
          var data = JSON.parse(result);
          if (!data.Message) {
            var companyDetail = data.Subscription.Company;
            dispatch<any>(myCompanyName(companyDetail.Name));
            dispatch<any>(myCompanyId(companyDetail.Id));
            dispatch<any>(myCompanyEmail(companyDetail.PrimaryContactEmail));
            dispatch<any>(
              myCompanyPrimaryContactName(companyDetail.PrimaryContactName)
            );
            dispatch<any>(
              myCompanyPrimaryContactNumber(companyDetail.PrimaryContactPhone)
            );
            dispatch<any>(
              myCompanySubscriptionCost(companyDetail.SubscriptionCost)
            );
            dispatch<any>(myCompanyCurrency(companyDetail.Currency));
            dispatch<any>(pendingMyCompanyDetail(false));
            dispatch<any>(myCompanyIsParent(companyDetail.IsParent));
            dispatch<any>(myCompanyParentId(companyDetail.ParentId));
            dispatch<any>(displaySelectedCompanyName(companyDetail.ParentId));
            dispatch<any>(
              setRegisterationDateCompany(companyDetail.RegistrationDate)
            );
          } else {
            dispatch<any>(displayErrorListMessage(data.Message + ":MyCompany"));
            dispatch<any>(pendingMyCompanyDetail(false));
          }
        })
        .catch((err) => {
          setTimeout(() => {
            dispatch<any>(pendingMyCompanyDetail(false));
          }, 500);
        });
    } catch (error) {
      setTimeout(() => {
        dispatch<any>(pendingMyCompanyDetail(false));
      }, 500);
    }
  };
};

export const myCompanyParentId = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyparentId: value,
      type: index.CharacterActionTypes.MY_COMPANY_PARENT_ID,
    });
  };
};
export const setRegisterationDateCompany = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyRegisterationDate: value,
      type: index.CharacterActionTypes.MY_COMPANY_REGISTERATION_DATE,
    });
  };
};

export const myCompanyIsParent = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyisParent: value,
      type: index.CharacterActionTypes.MY_COMPANY_IS_PARENT,
    });
  };
};
export const myCompanyName = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyName: value,
      type: index.CharacterActionTypes.MY_COMPANY_NAME,
    });
  };
};
export const myCompanyEmail = (result: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyEmail: result,
      type: index.CharacterActionTypes.MY_COMPANY_EMAIL,
    });
  };
};
export const myCompanyPrimaryContactName = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyPrimaryContactName: value,
      type: index.CharacterActionTypes.MY_COMPANY_PRIMARY_CONTACT_NAME,
    });
  };
};
export const myCompanyPrimaryContactNumber = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyPrimaryContactNumber: value,
      type: index.CharacterActionTypes.MY_COMPANY_PRIMARY_CONTACT_NUMBER,
    });
  };
};
export const myCompanySubscriptionCost = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanySubscriptionCost: value,
      type: index.CharacterActionTypes.MY_COMPANY_SUBSCRIPTION_COST,
    });
  };
};
export const myCompanyId = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyId: value,
      type: index.CharacterActionTypes.MY_COMPANY_ID,
    });
  };
};
export const myCompanyLine3 = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyLine3: value,
      type: index.CharacterActionTypes.MY_COMPANY_LINE3,
    });
  };
};
export const myCompanyCity = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyCity: value,
      type: index.CharacterActionTypes.MY_COMPANY_CITY,
    });
  };
};
export const myCompanyCurrency = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyCurrency: value,
      type: index.CharacterActionTypes.MY_COMPANY_CURRENCY,
    });
  };
};
export const myCompanyZipCode = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyZipCode: value,
      type: index.CharacterActionTypes.MY_COMPANY_ZIPCODE,
    });
  };
};
export const myCompanyCoutnry = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      myCompanyCoutnry: value,
      type: index.CharacterActionTypes.MY_COMPANY_COUNTRY,
    });
  };
};
export const pendingUnAssignPhneNumbers = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      pendingUnAssignPhoneNumbers: value,
      type: index.CharacterActionTypes.PENDING_UNASSIGN_PHONE_NUMBERS,
    });
  };
};
export const unassignPhoneNumberList = (list: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      unAssignPhoneNumber: list,
      type: index.CharacterActionTypes.UNASSIGN_PHONE_NUMBERS,
    });
  };
};
export const unAssignedPhoneNumbers = (
  smsServiceType: any,
  subscriptionId: any,
  companyId: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch<any>(pendingUnAssignPhneNumbers(true));
      numberService
        .getUnAssignPhoneNumber(smsServiceType, subscriptionId)
        .then((result) => {
          var data = JSON.parse(result);
          if (!data.Message) {
            var list =
              companyId !== null && companyId !== undefined && companyId !== ""
                ? data.filter((x: any) => x.CompanyId === companyId)
                : data;
            dispatch<any>(unassignPhoneNumberList(list));
            dispatch<any>(pendingUnAssignPhneNumbers(false));
          } else {
            dispatch<any>(displayErrorListMessage(data.Message + ":MyCompany"));
          }
        })
        .catch((err) => {
          setTimeout(() => {
            dispatch<any>(pendingUnAssignPhneNumbers(false));
          }, 500);
        });
    } catch (error) {
      setTimeout(() => {
        dispatch<any>(pendingUnAssignPhneNumbers(false));
      }, 500);
    }
  };
};
export const userAddCalledFromCompanyTab = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      userAddCalledFromCompanyTab: value,
      type: index.CharacterActionTypes.USER_ADD_CALLED_FROM_PHONE_NUMBER,
    });
  };
};
export const subscriptionAddCalledFromCompanyTab = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      subscriptionAddCalledFromCompanyTab: value,
      type: index.CharacterActionTypes.SUBSCRIPTON_ADD_CALLED_FROM_PHONE_NUMBER,
    });
  };
};
export const groupsAddCalledFromCompanyTab = (value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      groupAddCalledFromCompanyTab: value,
      type: index.CharacterActionTypes.GROUP_ADD_CALLED_FROM_PHONE_NUMBER,
    });
  };
};
