import * as React from "react";
import * as commonMethod from "../../commonMethods";
import ConfirmationDeleteComponent from "../sharedComponents/confirmationDeleteComponent";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import history from "../../history";
import "react-flags-select/css/react-flags-select.css";
import Select, { components } from "react-select";
import { getLoggedInUSerName, smsUrl } from "../../adalConfig";
// import { Props } from 'react-select/src/components';
// import { State } from 'react-select/src/Select';
const CaretDownIcon = () => {
  return <div className="number_arrow"></div>;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
export class NumbersAddUpdateComponent extends React.Component<any, any> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      PhoneNumber:
        this.props.calledFrom === "edit" ? this.props.editData.PhoneNumber : "",
      Provider:
        this.props.calledFrom && this.props.editData !== undefined
          ? this.props.editData!.Type || 0
          : 0, //provider ID
      ErrorPhoneNumber: "",
      dataTobeDeleted: [],
      autoFormat: true,
      showField: false,
      country: "",
      region: "",
      response: [],
      pendingTwillioData: false,
      twillioNumberResponse: [],
      twillioMobileResponse: [],
      areaCode: "",
      ErrorMessage: "",
      CountryCode: "",
      ErrorCountry: "",
      disableAreaCode: false,
      disableSearch: false,
      areaCodeThreeDigit: "",
      savingTwillioNumberDatabase: false,
      selectedPhoneObj: {},
      message: "",
      messageNumberRequired: "",
      showNumberDiv: false,
      smsUrl,
      smsURLRequired: "",
      firstRequest: false,
      twilBin: "This number if for texting only",
      twilBinRequired: "",
      activeTab: "Number Details",
      showLocalResponse: false,
      CompanyListState: [],
      SelectParentCompanyId:
        this.props.editData !== undefined
          ? this.props.editData.CompanyId || 0
          : 0,
      SelectParentCompanyName:
        this.props.editData !== undefined
          ? this.props.editData.CompanyName || "Select"
          : "Select",
      ProvidersListState: [],
      SelectProviderAccountId:
        this.props.editData !== undefined
          ? this.props.editData.ServiceProviderAccountId || 1
          : 1,
      SelectProviderAccountName:
        this.props.editData !== undefined
          ? this.props.editData.AccountName || "TwilioMain"
          : "TwilioMain", //newly added states to accomodate providers account options
      showWarningPopUp: "",
      dismissModal: false,
      disableCountryCode: false,
      buttonTextDispaly: "Save Changes",
      ErrorCompanyId: "",
      ErrorProviderAccountId: "",
      selectedCompanyId: "",
      companyDropDownDisable: false,
      trial:
        this.props.editData !== undefined ? this.props.editData.Trial : false,
      onlyReceiveMFA:
        this.props.editData !== undefined ? this.props.editData.OnlyReceiveMFA : false,
    };
  }
  componentDidMount() {
    this.setState({ dismissModal: false });
    var selectedPArentCompanyName = "";
    if (
      this.props.screenName !== "Numbers" &&
      this.props.screenName !== "Edit Numbers"
    ) {
      this.setState({
        showField: true,
      });
    }
    if (this.props.editData !== undefined) {
      // if (this.props.providersAccount.length > 0 && this.props.editData.CompanyId !== undefined) {
      //     selectedPArentCompanyName = this.props.providersAccount.filter((x: any) => x.Id === this.getProviderId(this.props.editData.Type)).map((x: any) => x.Name)[0]
      //     // this.setState({
      //     //     SelectParentCompanyName: 'Select',
      //     //     SelectParentCompanyId: this.props.editData.CompanyId
      //     // })
      // }
    }
    if (
      this.props.CompanyList !== undefined &&
      this.props.selectedCompanyId !== undefined &&
      this.props.selectedCompanyId !== ""
    ) {
      selectedPArentCompanyName = this.props.CompanyList.filter(
        (x: any) => x.Id === this.props.selectedCompanyId
      ).map((x: any) => x.Name)[0];
      this.setState({
        SelectParentCompanyName: selectedPArentCompanyName,
        SelectParentCompanyId: this.props.selectedCompanyId,
        companyDropDownDisable: true,
      });
    }

    this.state.Provider >= 0 &&
      this.props.providersAccountCall(this.state.Provider);
    //console.log(this.props.editData, "edit data");
  }

  //niyi
  getProviderId = (data: any) => {
    const iterator = this.props.providersList.values();
    let value = "";
    for (const providr of iterator) {
      if (data === providr.ProviderName) {
        value = providr.Id;
      }
    }
    //console.log(data)
    return value;
  };

  getDefaultServiceAccountName = () => {
    if (this.props.editData.PhoneNumber !== "") {
    }
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (this.state.Provider !== prevState.Provider) {
      this.state.Provider >= 0 &&
        this.props.providersAccountCall(this.state.Provider);
    }
    //this.props.providersAccount && console.log(this.props.providersAccount);
    //this.state.Provider && console.log(this.state.Provider, "provider");
  };
  //niyi
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.pendingResultFromTwillioProps !==
        prevState.pendingTwillioData ||
      nextProps.twillioData !== prevState.twillioNumberResponse ||
      nextProps.mobileTwillioData !== prevState.twillioMobileResponse ||
      nextProps.savingTwillioNumber !== prevState.savingTwillioNumberDatabase ||
      nextProps.showLocalNumber !== prevState.showLocalResponse ||
      nextProps.CompanyList !== prevState.CompanyListState ||
      nextProps.showWarningMessagePRops !== prevState.showWarningPopUp ||
      nextProps.phoneNumberProps !== prevState.PhoneNumber ||
      nextProps.buttonTextAction !== prevState.buttonTextDispaly
    ) {
      return {
        PhoneNumber: nextProps.phoneNumberProps,
        showLocalResponse: nextProps.showLocalNumber,
        twillioNumberResponse: nextProps.twillioData,
        pendingTwillioData: nextProps.pendingResultFromTwillioProps,
        twillioMobileResponse: nextProps.mobileTwillioData,
        savingTwillioNumberDatabase: nextProps.savingTwillioNumber,
        CompanyListState: nextProps.CompanyList,
        showWarningPopUp: nextProps.showWarningMessagePRops,
        buttonTextDispaly: nextProps.buttonTextAction,
      };
    } else return null;
  }
  IsValid() {
    var isValid = true;
    if (this.state.PhoneNumber.length === 0) {
      this.setState({ ErrorPhoneNumber: "Please enter valid Phone Number" });
      isValid = false;
    }
    if (this.state.SelectParentCompanyId === 0) {
      this.setState({ ErrorCompanyId: "Please select Provider's Account" });
      isValid = false;
    }
    return isValid;
  }
  addUpdateNumber = (e: any) => {
    if (this.IsValid() === true && this.state.ErrorPhoneNumber === "") {
      // var data = [];

      var validPhoneNumber =
        this.state.PhoneNumber.indexOf("+") === -1
          ? "+" + this.state.PhoneNumber
          : this.state.PhoneNumber;
      const sameNumber =
        this.props.numberistProps && this.props.numberistProps.length > 0
          ? this.props.numberistProps.find(
              (a: any) => a.PhoneNumber === validPhoneNumber
            )
          : null;
      let userData = getLoggedInUSerName();
      let obj = [
        {
          PhoneNumber: validPhoneNumber,
          Type: this.state.Provider,
          CompanyId: this.state.SelectParentCompanyId,
          ServiceProviderAccountId: this.state.SelectProviderAccountId,
          Trial: this.state.trial,
          OnlyReceiveMFA:this.state.onlyReceiveMFA,
          CreatedBy: userData.userName,
          ModifiedBy: userData.userName,
          CreatedDateTime: new Date(),
          ModifiedDateTime: new Date(),
        },
      ];

      if (this.props.screenName === "Edit Numbers") {
        // data = [{
        //     PhoneNumber: validPhoneNumber,
        //     Type: this.state.Provider,
        //     CompanyId: this.state.SelectParentCompanyId
        // }]

        this.props.addUpdateNumberProp("edit", obj);
        //this.props.addUpdatePhoneNumbers(obj)
      } else {
        if (sameNumber) {
          return this.setState({
            ErrorPhoneNumber: "This phone number already exists",
            validPhoneNumber: false,
          });
        }
        if (this.state.buttonTextDispaly === "Update Changes") {
          // data = [{
          //     "PhoneNumber": validPhoneNumber,
          //     "Type": this.state.Provider,
          //     "CompanyId": this.state.SelectParentCompanyId
          // }];
          this.props.addUpdateNumberProp("edit", obj);
          //this.props.addUpdatePhoneNumbers(obj)
        } else {
          // data = [{
          //     "PhoneNumber": validPhoneNumber,
          //     "Type": this.state.Provider,
          //     "CompanyId": this.state.SelectParentCompanyId
          // }];
          this.props.addUpdateNumberProp("add", obj);
          //this.props.addUpdatePhoneNumbers(obj)
        }
      }
    }
  };

  IsValidBuyingNumber(obj: any, smsUrl: any, twilBin: any) {
    var isValid = true;
    if (obj.phone_number === undefined) {
      isValid = false;
      this.setState({
        messageNumberRequired: "Please select number",
      });
    }
    if (smsUrl === "") {
      isValid = false;
      this.setState({
        smsURLRequired: "This field is required",
      });
    }
    if (twilBin === "") {
      isValid = false;
      this.setState({
        twilBinRequired: "This field is required",
      });
    }
    return isValid;
  }
  deletePhoneNumber = () => {
    // var validPhoneNumber =
    //   this.state.PhoneNumber.indexOf("+") === -1
    //     ? "+" + this.state.PhoneNumber
    //     : this.state.PhoneNumber;
    // let userData = getLoggedInUSerName();
    var data = [
      {
        PhoneNumber: this.state.PhoneNumber,
        Type: this.state.Provider,
      },
    ];
    this.setState({
      dataTobeDeleted: data,
      dismissModal: false,
    });
    this.props.deletePhoneNumber(data);
  };
  shownOtherFields = (e: any) => {
    this.setState({
      showField: true,
    });
    //  this.props.twillioList([])
  };
  onSelectFlag = (e: any) => {
    this.setState({
      country: e.toString().toLowerCase(),
      ErrorCountry: "",
      disableAreaCode: false,
      disableSearch: false,
      messageNumberRequired: "",
      smsUrl,
      smsURLRequired: "",
    });
  };
  searchNumbers = (firstRequest: any) => {
    this.setState({
      disableCountryCode: true,
      autoFormat: false,
    });
    if (this.state.country === "") {
      this.setState({
        disableSearch: true,
        ErrorCountry: "Please select country",
        showNumberDiv: false,
        messageNumberRequired: "",
      });
    } else {
      if (
        this.state.country.toUpperCase() === "GB" ||
        this.state.country.toUpperCase() === "AU"
      ) {
        this.props.searchNumberFromTwillio(
          this.state.country.toUpperCase(),
          "MOBILE",
          this.state.areaCode,
          firstRequest
        );
        this.setState({
          disableSearch: false,
          ErrorCountry: "",
          selectedPhoneObj: {},
          showNumberDiv: true,
          messageNumberRequired: "",
        });
      } else {
        this.setState({
          disableSearch: false,
          ErrorCountry: "",
          selectedPhoneObj: {},
          showNumberDiv: true,
          messageNumberRequired: "",
        });
        this.props.searchNumberFromTwillio(
          this.state.country.toUpperCase(),
          "LOCAL",
          this.state.areaCode,
          firstRequest
        );
      }
    }
  };
  buyNumberFromTwillio = (
    obj: any,
    smsUrl: any,
    twilBin: any,
    callingFrom: any
  ) => {
    var callingByFrom =
      callingFrom === "Company:edit"
        ? "Company:edit"
        : callingFrom === "Company:addGroup"
        ? "Company:addGroup"
        : callingFrom === "Company:editGroup"
        ? "Company:editGroup"
        : callingFrom === "Company:add"
        ? "Company:add"
        : callingFrom === "EditUsers"
        ? "EditUsers"
        : callingFrom === "addUsers"
        ? "addUsers"
        : callingFrom === "EditGroups"
        ? "EditGroups"
        : callingFrom === "addGroups"
        ? "addGroups"
        : "Numbers";
    var countryCode =
      obj.iso_country === "US" ? 1 : obj.iso_country === "GB" ? 44 : 61;
    if (this.IsValidBuyingNumber(obj, smsUrl, twilBin) === true) {
      this.setState({
        messageNumberRequired: "",
      });
      if (callingByFrom === "Company:edit") {
        this.props.userEditTabHeading("edit");
      } else {
        this.props.userEditTabHeading("");
      }
      this.props.purchaseNumberFromTwilio(
        obj.phone_number,
        smsUrl,
        twilBin,
        callingByFrom,
        countryCode
      );
    }
  };
  buyButtons() {
    return (
      <div className="buyBtn_div">
        <div className="text-left d-flex align-items-start justify-content-between phoneNogridsearch">
          <div
            className={
              this.state.smsURLRequired !== ""
                ? "validationClass form-group"
                : "form-group"
            }
          >
            <label className="form-label">SmsUrl</label>
            <input
              type="text"
              className="form-control"
              placeholder="SmsUrl"
              onChange={(e) => {
                this.setState({
                  smsUrl: e.target.value,
                  smsURLRequired: "",
                });
              }}
              value={this.state.smsUrl}
            />
            <small className="form-text">{this.state.smsURLRequired}</small>
          </div>
          <div
            className={
              this.state.twilBinRequired !== ""
                ? "validationClass form-group"
                : "form-group"
            }
          >
            <label className="form-label">TwiMil Bln</label>
            <input
              type="text"
              className="form-control"
              placeholder="TwiMil Bln"
              onChange={(e) => {
                this.setState({
                  twilBin: e.target.value,
                  twilBinRequired: "",
                });
              }}
              value={this.state.twilBin}
            />
            <small className="form-text">{this.state.twilBinRequired}</small>
          </div>
          <div className="searchno_btn form-group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                this.buyNumberFromTwillio(
                  this.state.selectedPhoneObj,
                  this.state.smsUrl,
                  this.state.twilBin,
                  this.props.screenName
                )
              }
            >
              Buy
            </button>
          </div>
        </div>
        <div
          className={
            this.state.messageNumberRequired !== "" ? "validationClass" : ""
          }
        >
          <small className="form-text">
            {this.state.messageNumberRequired}
          </small>
        </div>
      </div>
    );
  }
  cancelButton() {
    if (this.props.calledFrom === "edit" || this.props.calledFrom === "add") {
      this.props.changeState();
    } else if (this.props.calledFrom !== "Numbers") {
      history.push("Users", { callingFrom: "Numbers" });
    } else {
      this.props.changeState();
    }
  }
  allButtons() {
    return (
      <div className="btnDiv btnDeleteDiv d-flex justify-content-between align-items-center">
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => this.addUpdateNumber(e)}
          >
            {this.state.buttonTextDispaly}
          </button>
          <button
            type="button"
            className="btn btn-default cancelBtn"
            onClick={() => this.breadCrumbNavigation()}
          >
            Cancel
          </button>
          {this.props.calledFrom === "edit" ? null : this.props
              .loggedInUserRole === -10 &&
            this.state.buttonTextDispaly !== "Update Changes" ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => this.shownOtherFields(e)}
            >
              Buy Number
            </button>
          ) : null}
        </div>
        <div>
          {this.props.calledFrom === "edit" ? (
            <button
              type="button"
              className="btn btn-primary delete_btn"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <span className="font-icon">&#107;</span>Delete
            </button>
          ) : null}
        </div>
      </div>
    );
  }
  twillioMobileResponse() {
    return this.state.twillioMobileResponse !== undefined ? (
      this.state.twillioMobileResponse.length !== 0 ? (
        <ul>
          {this.state.twillioMobileResponse.map((item: any, index: number) => (
            <li key={index}>
              <div className="custom-control custom-radio circle_check">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="Mobile"
                  id={"mobile" + index}
                  value={item.phone_number}
                  onChange={(e: any) => {
                    this.setState({
                      selectedPhoneObj: item,
                      messageNumberRequired: "",
                    });
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"mobile" + index}
                ></label>
              </div>
              {item.phone_number}
            </li>
          ))}
        </ul>
      ) : (
        <div>No Records Found</div>
      )
    ) : null;
  }
  localTwillioResponse() {
    return this.state.twillioNumberResponse !== undefined ? (
      this.state.twillioNumberResponse.length !== 0 ? (
        <ul>
          {this.state.twillioNumberResponse.map((item: any, index: number) => (
            <li key={index}>
              <div className="custom-control custom-radio circle_check">
                <input
                  className="custom-control-input"
                  type="radio"
                  name="Local"
                  id={"local" + index}
                  value={item.phone_number}
                  onChange={(e: any) => {
                    this.setState({
                      selectedPhoneObj: item,
                      messageNumberRequired: "",
                    });
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"local" + index}
                ></label>
              </div>
              {item.phone_number}
            </li>
          ))}
        </ul>
      ) : (
        <div>No Records Found</div>
      )
    ) : (
      <div>No sd Records Found</div>
    );
  }
  addNumberForm() {
    const doProviderName = (data: any) => {
      const iterator = this.props.providersList.values();
      let value = "";
      for (const providr of iterator) {
        if (data === providr.Id) {
          value = providr.ProviderName;
        }
      }
      return value;
    };
    return (
      <div>
        <div className="row text-left" style={{ width: "100%" }}>
          <div className="col-md-6">
            <div
              className={
                this.state.ErrorPhoneNumber !== ""
                  ? "form-group validationClass"
                  : "form-group"
              }
            >
              <label className="form-label">Number</label>
              {this.props.calledFrom === "edit" ? (
                <input
                  type="text"
                  className="form-control non-editable"
                  disabled={true}
                  value={this.state.PhoneNumber}
                  onChange={(PhoneNumber: any) => {
                    this.setState({
                      PhoneNumber: PhoneNumber.target.value,
                    });
                  }}
                />
              ) : (
                <PhoneInput
                  placeholder={"Phone Number"}
                  autoFormat={this.state.autoFormat}
                  enableSearch={true}
                  disableCountryCode={this.state.disableCountryCode}
                  value={this.state.PhoneNumber}
                  onChange={(value: any, country: any) => {
                    this.setState({
                      disableCountryCode: false,
                    });
                    var phoneNumber = "+" + value;
                    if (
                      country.dialCode === "45" ||
                      country.dialCode === "34"
                    ) {
                      this.setState({
                        autoFormat: false,
                      });
                    } else {
                      this.setState({
                        autoFormat: true,
                      });
                    }
                    var indexOfCountryCode = phoneNumber.indexOf(
                      country.dialCode
                    );
                    if (indexOfCountryCode !== -1) {
                      //debugger
                      var split = phoneNumber.split("+" + country.dialCode);

                      var validatPhoneNumber = commonMethod.validPhoneNumber(
                        country.dialCode + " " + split[1]
                      );
                      if (country.dialCode === "61") {
                        validatPhoneNumber =
                          commonMethod.validPhoneNumberForAustralia(split[1]);
                      }
                      if (validatPhoneNumber === true) {
                        this.props.phoneNumberValue(value);
                        this.setState({
                          ErrorPhoneNumber: "",
                        });
                      } else {
                        this.setState({
                          ErrorPhoneNumber: "Please enter valid PhoneNumber",
                          validPhoneNumber: false,
                        });
                      }
                    }
                  }}
                />
              )}
              <small className="form-text ">
                {this.state.ErrorPhoneNumber}{" "}
              </small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Provider</label>
              <Select
                className="providr_drop"
                isSearchable={false}
                isDisabled={this.props.calledFrom === "edit" ? true : false}
                placeholder="Select"
                isClearable={false}
                components={{ DropdownIndicator }}
                value={[
                  {
                    label: doProviderName(this.state.Provider),
                    value: this.state.Provider,
                  },
                ]}
                onChange={(opt: any) => {
                  this.setState({ Provider: parseInt(opt.value) });
                }}
                options={this.props.providersList.map((a: any) => {
                  return {
                    label: a.ProviderName,
                    value: a.Id,
                  };
                })}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div
              className={
                this.state.ErrorCompanyId !== ""
                  ? "form-group validationClass"
                  : "form-group"
              }
            >
              <label className="form-label">Provider Account</label>
              <Select
                isDisabled={this.state.showField ? true : false}
                placeholder="Select"
                components={{
                  DropdownIndicator,
                }}
                value={[
                  {
                    label: this.state.SelectProviderAccountName,
                    value: this.state.SelectProviderAccountId,
                  },
                ]}
                onChange={(opt: any) =>
                  this.setState({
                    ErrorCompanyId: "",
                    SelectProviderAccountId: opt.value,
                    SelectProviderAccountName: opt.label,
                  })
                }
                options={
                  this.props.providersAccount.length >= 0
                    ? this.props.providersAccount.map((opt: any) => ({
                        label: opt.Name,
                        value: opt.Id,
                      }))
                    : []
                }
              />
              <small className="form-text ">{this.state.ErrorCompanyId}</small>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className={
                this.state.ErrorCompanyId !== ""
                  ? "form-group validationClass"
                  : "form-group"
              }
            >
              <label className="form-label">Company</label>
              <Select
                isDisabled={this.state.companyDropDownDisable}
                placeholder="Select"
                components={{
                  DropdownIndicator,
                }}
                value={[
                  {
                    label: this.state.SelectParentCompanyName,
                    value: this.state.SelectParentCompanyId,
                  },
                ]}
                onChange={(opt: any) =>
                  this.setState({
                    ErrorCompanyId: "",
                    SelectParentCompanyId: opt.value,
                    SelectParentCompanyName: opt.label,
                  })
                }
                options={
                  this.props.CompanyList.length > 0
                    ? this.props.CompanyList.map((opt: any) => ({
                        label: opt.Name,
                        value: opt.Id,
                      }))
                    : []
                }
              />
              <small className="form-text ">{this.state.ErrorCompanyId}</small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label className="custom-control custom-checkbox m-0 checkbox_right">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={() => {
                    // this.props.disableForAddEdit(!this.state.disabled)
                    this.setState({ trial: !this.state.trial });
                  }}
                  checked={this.state.trial}
                />
                <span className="custom-control-label">Trial</span>
              </label>
            </div>
            <div className="form-group mt-3">
              <label className="custom-control custom-checkbox m-0 checkbox_right">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={() => {
                    // this.props.disableForAddEdit(!this.state.disabled)
                    this.setState({ onlyReceiveMFA: !this.state.onlyReceiveMFA });
                  }}
                  checked={this.state.onlyReceiveMFA}
                />
                <span className="custom-control-label">ReceiveOnly MFA</span>
              </label>
            </div>
          </div>
        </div>
        {this.props.calledFrom === "add" ? this.allButtons() : null}
      </div>
    );
  }
  renderHtml() {
    return (
      <div className="nav-tabs-top row">
        <div className="tab-content border-0">
          <div className="tab-pane fade show active" id="user-edit-account">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-xl-6 col-xxl-7">
                  <div className="formDiv">
                    {/* {this.addNumberForm()} */}
                    {this.props.screenName === "Numbers" ||
                    this.props.screenName === "Edit Numbers" ||
                    this.state.PhoneNumber !== ""
                      ? this.addNumberForm()
                      : null}
                    <div
                      className={
                        this.state.showField === true
                          ? "d-flex align-items-start justify-content-between phoneNogridsearch"
                          : "dislayNone d-flex align-items-start justify-content-between phoneNogridsearch"
                      }
                    >
                      <div
                        className={
                          this.state.ErrorCountry !== ""
                            ? "form-group validationClass "
                            : "form-group"
                        }
                      >
                        <label className="form-label">Select Country</label>
                        <ReactFlagsSelect
                          searchable={false}
                          placeholder="Select Country"
                          countries={["US", "GB", "AU", "CA"]}
                          onSelect={(e: any) => this.onSelectFlag(e)}
                        />
                        <small className="form-text ">
                          {this.state.ErrorCountry}{" "}
                        </small>
                      </div>
                      <div
                        className={
                          this.state.ErrorMessage !== ""
                            ? "form-group validationClass"
                            : "form-group"
                        }
                      >
                        <label className="form-label">Search</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Search"
                          onChange={(e) => {
                            this.setState({
                              areaCode: e.target.value,
                              ErrorMessage: "",
                            });
                          }}
                          value={this.state.areaCode}
                        />
                        <small className="form-text ">
                          {this.state.ErrorMessage}
                        </small>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Provider</label>
                        <select
                          value={this.state.Provider}
                          onChange={(e) => console.log(e)}
                          className="custom-select"
                        >
                          <option value={0}>Twilio</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary searchno_btn"
                          disabled={this.state.disableSearch}
                          onClick={() => {
                            var data = false;
                            if (this.state.firstRequest === false) {
                              data = true;
                              this.setState({
                                firstRequest: true,
                              });
                            }

                            this.searchNumbers(data);
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.showNumberDiv === true ? (
                  <div className="col-lg-6">
                    <div className={"countryBox formDiv"}>
                      <div className={"text-left"}>
                        {this.state.pendingTwillioData === true ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="number_list">
                            {this.state.showLocalResponse !== undefined
                              ? this.buyButtons()
                              : null}
                            <div className="mobNumberList">
                              {this.state.showLocalResponse !== undefined
                                ? this.state.showLocalResponse === true
                                  ? this.localTwillioResponse()
                                  : this.twillioMobileResponse()
                                : null}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {this.props.calledFrom === "edit" ? this.allButtons() : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
  breadCrumbNavigation = () => {
    //For hide company list
    this.props.changeState();
    if (this.props.screenName === "Company:add") {
      this.props.userEditTabHeading("");
      history.push("Company", { callingFrom: "Company:add" });
    } else if (this.props.screenName === "Company:edit") {
      this.props.userEditTabHeading("edit");
      history.push("Company", { callingFrom: "Company:edit" });
    } else if (this.props.screenName === "Company:addGroup") {
      history.push("Company", { callingFrom: "Company:addGroup" });
    } else if (this.props.screenName === "Company:editGroup") {
      history.push("Company", { callingFrom: "Company:editGroup" });
    }
  };
  renderDeleteConfirmation() {
    return (
      <div
        className={
          this.state.dismissModal === true
            ? "modal fade show"
            : "modal fade show displayBlock"
        }
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Confirmation
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              The number {this.state.showWarningPopUp} was not found in the
              Twilio service. The number will need to be removed manually using
              the Twilio adminstration portal. Are you sure you wish to
              continue?'
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default cancelBtn"
                onClick={() => this.setState({ dismissModal: true })}
                data-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary "
                data-dismiss="modal"
                onClick={() => {
                  this.setState({ dismissModal: true });
                  this.props.deleteFromDatabase(this.state.dataTobeDeleted);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          {this.props.screenName !== "Numbers" &&
          this.props.screenName !== "Edit Numbers" ? (
            <h2 className="companyName  text-left">
              <span
                className="iconNavigation cursor_pointer"
                onClick={() => {
                  this.breadCrumbNavigation();
                }}
              >
                <i
                  className="fa fa-chevron-left cursor_pointer"
                  aria-hidden="true"
                ></i>
              </span>
              {this.props.screenName === "Company:add" ||
              this.props.screenName === "Company:edit" ||
              this.props.screenName === "Company:editGroup" ||
              this.props.screenName === "Company:addGroup"
                ? "Company /" + this.state.SelectParentCompanyName
                : this.props.screenName}{" "}
              / <b>New Number</b>
            </h2>
          ) : (
            <h2 className="companyName  text-left">
              <span
                className="iconNavigation cursor_pointer"
                onClick={() => this.props.changeState()}
              >
                <i
                  className="fa fa-chevron-left cursor_pointer"
                  aria-hidden="true"
                ></i>
              </span>
              Numbers /{" "}
              {this.props.calledFrom === "edit" ? (
                <b>{this.state.PhoneNumber}</b>
              ) : (
                <b>New Number</b>
              )}
            </h2>
          )}

          <Tabs
            className="tabGroupMember"
            defaultActiveKey={this.state.activeTab}
            onSelect={(currentTabName: any) => {
              this.setState({
                activeTab: currentTabName,
              });
            }}
            id="uncontrolled-tab-example"
          >
            <Tab
              eventKey="Number Details"
              title={
                <span>
                  <span className="font-icon">&#67;</span> Number Details
                </span>
              }
            >
              {this.renderHtml()}
            </Tab>
          </Tabs>

          <ConfirmationDeleteComponent
            deleteSucces={() => this.deletePhoneNumber()}
          />
          {this.state.showWarningPopUp !== "" &&
          this.state.showWarningPopUp !== undefined
            ? this.renderDeleteConfirmation()
            : null}
        </div>
      </React.Fragment>
    );
  }
}
